/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo, useState } from 'react';
import { Column, usePagination, useTable, useFilters } from 'react-table';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import qs from 'qs';
import CONSTANTS from '../../constants';
import { DeleteSvg, EditSvg, PlusIconSvg } from '../../assets/images';
import { deletePartyName, editPartyName, getAllPartyNames } from '../../API/partyNames';
import { SkeletonText } from '../Skeleton';
import { IPaginationData } from '../../interfaces';
import { IPartyNames } from '../../interfaces/contracts';
import Pagination from '../pagination/Pagination';
import ColumnFilter from './ColumnFilter';
import'./PartyNameTable.scss';

const PartyNameTable: React.FC = () => {
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
        totalCount: 0,
    };
    const defaultParty: IPartyNames = {
        id: 0,
        partyName: '',
        partyAlias: '',
    };
    const defaultPartyNames = () => {
        const partyNames: IPartyNames[] = [];
        for (let i = 0; i <= 9; i+=1) {
            partyNames.push({
                id: CONSTANTS.LOADING.NUMBER,
                partyName: CONSTANTS.LOADING.TEXT,
                partyAlias: CONSTANTS.LOADING.TEXT
            });
        }
        return partyNames;
    };

    const [partyNames, setPartyNames] = useState<IPartyNames[]>(defaultPartyNames);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [showEditModal, setShowEditModal] = useState(false);
    const [existingParty, setExistingParty] = useState<IPartyNames>(defaultParty);

    const COLUMNS : Column<IPartyNames>[] = [
        {
            Header: 'Sl. No.',
            disableFilters: true
        },
        {
            Header: 'Party Name',
            accessor: 'partyName',
            minWidth: 300,
            Cell: ({ value }) => <SkeletonText text={value} width={200} />,
        },
        {
            Header: 'Party Alias',
            accessor: 'partyAlias',
            minWidth: 300,
            Cell: ({ value }) => <SkeletonText text={value} width={200} />,
        },
        {
            Header: 'Action',
            accessor: 'id',
            disableFilters: true
        }
    ];
    const columns = useMemo(() => COLUMNS, []);
    const defaultColumn = useMemo(() => (
        {
            Filter: ColumnFilter
        }
    ), []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: partyNames || [],
            defaultColumn,
            manualPagination: false,
            autoResetPage: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
        },
        useFilters,
        usePagination,
    );

    const updatePartyNames = async () => {
        const allPartyNames = await getAllPartyNames();
        if (allPartyNames?.apiStatus === 'SUCCESS') {
            setPartyNames(allPartyNames?.partyNames);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: allPartyNames.totalPartyNames,
            });
        }
    };

    const handleEditClick = async (cell: any) => {
        const { id, partyName, partyAlias } = cell?.row?.original;
        setExistingParty({
            id,
            partyName,
            partyAlias,
        });
        setShowEditModal(true);
    };

    const editParty = async (party: IPartyNames) => {
        const response = await editPartyName(party);
        if (response?.apiStatus === 'SUCCESS') {
            updatePartyNames();
        } else {
            toast.error('Unable to Edit');
        }
        setShowEditModal(false);
    }

    const removeParty = async (rowId: number) => {
        const response = await deletePartyName(rowId);
        if (response?.apiStatus === 'SUCCESS') {
            updatePartyNames();
        } else {
            toast.error('Unable to delete');
        }
    };

    useEffect(() => {
        updatePartyNames();
    }, [pageIndex, pageSize]);

    return (
        <>
            <div className="partyname-table">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => (
                                    <th
                                        {...column.getHeaderProps({style: {textAlign: 'left'}})}
                                    >
                                        {column.render('Header')}
                                        {column.canFilter ? column.render('Filter') : null }
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header==='Sl. No.') {
                                            return ( <td> {i+1} </td> )
                                        }
                                        if (cell.column.Header==='Action') {
                                            return (
                                                <td>
                                                    <EditSvg title="edit" style={{cursor: 'pointer'}} onClick={()=>handleEditClick(cell)}/>
                                                    <DeleteSvg title="delete" style={{cursor: 'pointer'}} onClick={()=>removeParty(cell.value)}/>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto'
                                                    },
                                                })}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            </div>
            <Modal
                isOpen={showEditModal}
                onRequestClose={() => setShowEditModal(false)}
                contentLabel="Edit PartyName Modal"
                className="edit-partyname-modal"
                shouldCloseOnOverlayClick={false}
            >
                <PlusIconSvg className="close-btn" onClick={() => setShowEditModal(false)} />
                <div className="modal-body">
                    <h3>Edit Party Name</h3>
                    <div className="form-inputs">
                        <div>
                            <label htmlFor="partyName">Party Name</label>
                            <input 
                                type="text" 
                                id="partyName" 
                                placeholder="Enter Party Name ..."
                                value={existingParty.partyName}
                                onChange={e => setExistingParty({...existingParty, partyName: e.target.value})}
                            />
                        </div>
                        <div>
                            <label htmlFor="partyAlias">Party Alias</label>
                            <input 
                                type="text"
                                id="partyAlias"
                                placeholder="Enter Party Alias ..."
                                value={existingParty.partyAlias}
                                onChange={e => setExistingParty({...existingParty, partyAlias: e.target.value})}
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <button
                        type="button"
                        className="primary-btn update-btn"
                        disabled={existingParty.partyName.trim() === '' || existingParty.partyAlias.trim() === ''}
                        onClick={() => editParty(existingParty)}
                    >
                        Update
                    </button>
                </div>
            </Modal>
        </>
    )
};

export default PartyNameTable;