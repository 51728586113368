/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { PlusIconSvg } from '../../assets/images';
import { IFieldsData } from '../../interfaces/document';
import { addNewParty } from '../../API/partyNames';
import './PartyNameModal.scss';

interface Props {
    fieldsData: IFieldsData[];
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const PartyNameModal: React.FC<Props> = ({
    fieldsData,
    isModalOpen,
    onModalClose
}) => {
    const [partyName, setPartyName] = useState('');
    const [partyAlias, setPartyAlias] = useState('');

    const addNewPartyName = async () => {
        const response = await addNewParty(partyName, partyAlias);
        if (response?.apiStatus === 'SUCCESS') {
            fieldsData.map(field => {
                if (field.name === 'partyName') {
                    // eslint-disable-next-line no-param-reassign
                    field.value = [{label: partyName, value: partyAlias}]
                }
                return field;
            });
            toast.success('PartyName added successfully');
        } else {
            toast.error('Unable to add partyname');
        }
        onModalClose(false);
    };

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={() => onModalClose(false)}
            className="partyname__modal"
            shouldCloseOnOverlayClick={false}
        >
            <PlusIconSvg className="close-btn" onClick={() => onModalClose(false)} />
            <div className="heading">
                <h1>Add PartyName Details</h1>
                <div className="form-inputs">
                    <div>
                        <label htmlFor="partyName">Party Name</label>
                        <input 
                            type="text" 
                            id="partyName" 
                            placeholder="Enter Party Name ..."
                            onChange={e => setPartyName(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="partyAlias">Party Alias</label>
                        <input 
                            type="text"
                            id="partyAlias"
                            placeholder="Enter Party Alias ..."
                            onChange={e => setPartyAlias(e.target.value)}
                        />
                    </div>
                </div>
                
            </div>
            <div className="buttons">
                <button
                    type="button"
                    className="primary-btn"
                    disabled={partyName.trim() === '' || partyAlias.trim() === ''}
                    onClick={addNewPartyName}
                >
                    Save
                </button>
            </div>
        </Modal>
    );
}

export default PartyNameModal;
