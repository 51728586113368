import React, { useEffect, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { downloadReportHistory } from '../../API/reports';
import { DownloadIcon, SortIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IReportsHistory } from '../../interfaces/reports';
import { DateTimeColumn, NoDocumentsFound } from '../General';
import { SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';

const columnMapping: IStringObject = {
    runBy: 'runBy',
    runTime: 'runTime',
};

const DEFAULT_SORT: ISort = {
    id: 'runTime',
    desc: true,
};

const ActionColumn = (row: Row<IReportsHistory>) => {
    const {
        original: { id, key },
    } = row;
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportName = queryParams.get('report_name');
    return (
        <div className="action-icons">
            {/* <DuplicateIcon
                style={{ cursor: 'pointer', marginRight: '10px' }}
                title="Duplicate Report"
            /> */}
            {!key ? (
                <Skeleton width={150} />
            ) : (
                <DownloadIcon
                    style={{ cursor: 'pointer', width: '30px', height: '14px' }}
                    onClick={() => downloadReportHistory(id, reportName)}
                    title="Download Report"
                />
            )}
        </div>
    );
};

const NumberOfResultsColumn = ({ value }: { value: number | null }): JSX.Element => (
    <>{value === -1 ? <Skeleton width={150} /> : value}</>
);

const COLUMNS: Column<IReportsHistory>[] = [
    {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => i + 1,
    },
    {
        Header: 'Run By',
        accessor: 'runBy',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Date',
        accessor: 'runTime',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 300,
        disableSortBy: true,
    },
    {
        Header: 'Number Of Results',
        accessor: 'numberOfRecords',
        Cell: ({ value }) => <NumberOfResultsColumn value={value} />,
        minWidth: 250,
        disableSortBy: false,
    },
    {
        Header: 'Output',
        accessor: 'id',
        Cell: ({ row }) => <ActionColumn {...row} />,
        minWidth: 300,
        disableSortBy: true,
    },
];

interface Props {
    data: IReportsHistory[];
    pagination: IPaginationData;
    fetchReportHistory: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

// eslint-disable-next-line max-lines-per-function
const ReportRunsTableTab: React.FC<Props> = ({ data, pagination, fetchReportHistory }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IReportsHistory>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchReportHistory(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'runTime',
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);
    return (
        <div className="report-runs-table">
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth
                                                    ? column.minWidth
                                                    : 'auto',
                                            },
                                        })}
                                    >
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted && (
                                                <SortIcon
                                                    className={`sort ${
                                                        column.isSortedDesc ? 'desc' : 'asc'
                                                    } `}
                                                />
                                            )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => (
                                        <td
                                            className={cell.column.id}
                                            {...cell.getCellProps({
                                                style: {
                                                    minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {page.length === 0 && <NoDocumentsFound heading="No Data Found" />}
            </div>
            {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    );
};

export default ReportRunsTableTab;
