import React from 'react'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'

interface Props {
    field: IField;
    isDisabled: boolean;
    value: number;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const Number: React.FC<Props> = ({ field, isDisabled, value, error, documentDispatch }: Props) => (
    <input
        type="number"
        className={`input-text ${error ? 'error' : ''}`}
        name={field.name}
        disabled={isDisabled}
        value={value}
        onChange={(e) =>
            documentDispatch &&
            documentDispatch({
                type: 'number',
                inputName: field.name,
                value: parseInt(e.target.value, 10),
            })
        }
        autoComplete="off"
    />
);

export default Number
