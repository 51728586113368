import { IAPIResponseStatus } from "../interfaces";
import { IPartyNames } from "../interfaces/contracts";
import { deleteRequest, getRequest, postRequest } from "../utils/api-request";

const getAllPartyNames = async () : Promise<{ apiStatus: IAPIResponseStatus; partyNames: IPartyNames[]; totalPartyNames: number; } | undefined> => {
    try {
        const res = await getRequest(`party-name`, false);
        return {
            apiStatus: 'SUCCESS',
            partyNames: res?.response.partyNames,
            totalPartyNames: res?.response.count
        };
    } catch (err) {
        return undefined;
    }
}

const addNewParty = async (
    partyName: string,
    partyAlias: string
): Promise<{ apiStatus: IAPIResponseStatus} | undefined> => {
    try {
        const body = {partyName, partyAlias};
        const res = await postRequest(`party-name/add-new-party`, body);
        if (res) {
            return { apiStatus: res?.apiStatus };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
}

const editPartyName = async (party: IPartyNames ): Promise<{ apiStatus: IAPIResponseStatus} | undefined> => {
    try {
        const res = await postRequest(`party-name/edit-party-name`, party);
        if (res) {
            return { apiStatus: res?.apiStatus };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
}

const deletePartyName = async (
    partyNameId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`party-name/${partyNameId}`, false);
        if (res?.apiStatus) return { apiStatus: res?.apiStatus };
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
}

export {
    getAllPartyNames,
    addNewParty,
    editPartyName,
    deletePartyName,
};