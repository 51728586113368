/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { getDocumentEvents } from '../../API/notifications';
import { SkeletonText } from '../../components/Skeleton';
import CONSTANTS from '../../constants';
import { IApprovalDocStage, IDocEvent, IEventTypes } from '../../interfaces/document';
import { formatDateForEvent } from '../../utils/date';
import './DocDetailEvents.scss';

interface Props {
    docId: number;
    allowReviseByAnyone: boolean;
    approvers: IApprovalDocStage[];
}

const defaultEvents = () => {
    const events: IDocEvent[] = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= 3; i++) {
        events.push({
            date: CONSTANTS.LOADING.DATE,
            eventType: CONSTANTS.LOADING.TEXT as IEventTypes,
        });
    }

    return events;
};

const DocDetailEvents: React.FC<Props> = ({ docId, allowReviseByAnyone, approvers }) => {
    const [timelineEvents, seTimelineEvents] = useState(defaultEvents());

    useEffect(() => {
        const fetchTimeline = async () => {
            if (docId) {
                const res = await getDocumentEvents(docId, allowReviseByAnyone);
                if (res?.apiStatus === 'SUCCESS') {
                    seTimelineEvents(res.timeline);
                }
            }
        };
        fetchTimeline();
    }, [docId, approvers]);

    const getEventDescription = (event: IDocEvent) => {
        switch (event.eventType) {
            case 'Document.DocumentClaimedEvent':
                return `${event.actionTakenBy} has claimed the ${event.isDCO ? 'DCO' : 'document'}`;
                case 'Document.DocumentUnClaimedEvent' :
                    return `${event.actionTakenBy} has unclaimed the ${event.isDCO ? 'DCO' : 'document'}`;
            case 'Document.CommentAdded':
                return `${event.actionTakenBy} has ${event.actionTakenOnComment} a comment "${event.comment}" ${formatDateForEvent(event.date)}`;
            case 'Document.Created':
                return `Created ${formatDateForEvent(event.date)}${  event.actionTakenBy ? ` by ${event.actionTakenBy}` : ''}`;
            case 'Document.Submitted':
                return `Submitted ${formatDateForEvent(event.date)}${  event.actionTakenBy ? ` by ${event.actionTakenBy}` : ''}`;
            case 'Document.AssignedToApprover':
                if (event.group) {
                    return `Assigned to ${event.group.name} group ${formatDateForEvent(
                        event.date,
                    )}`;
                }
                return `Assigned to ${event.actionTakenBy} ${formatDateForEvent(
                    event.date,
                )} for Approving`;
            case 'Document.AssignedToReviewer':
                return `Assigned to ${event.actionTakenBy} ${formatDateForEvent(
                    event.date,
                )} for Reviewing`;
            case 'Document.Approved': {
                if (event.delegator) {
                    return `Approved by ${event.actionTakenBy} on behalf of ${
                        event.delegator
                    }  ${formatDateForEvent(event.date)}`;
                }
                return `Approved by ${event.actionTakenBy}  ${formatDateForEvent(event.date)}`;
            }
            case 'Document.Rejected': {
                if (event.delegator) {
                    return `Rejected by ${event.actionTakenBy} on behalf of ${
                        event.delegator
                    }  ${formatDateForEvent(event.date)}`;
                }
                if (event.actionTakenBy) {
                    return `Rejected by ${event.actionTakenBy}  ${formatDateForEvent(event.date)}`;
                }
                return `Rejected ${formatDateForEvent(event.date)}`;
            }
            case 'Document.Published':
                return `Published ${formatDateForEvent(event.date)}`;
            case 'Document.Sunset':
                return `Document Sunset ${formatDateForEvent(event.date)}`;
            case 'Document.Obsoleted':
                return `${event.isDCO ? 'DCO' : 'Document'} has become obsolete ${formatDateForEvent(event.date)}`;
            case 'Document.ObsoletedByAdmin':
                return `${event.isDCO ? 'DCO' : 'Document'} has been obsoleted by Admin ${formatDateForEvent(event.date)}`;
            case 'Document.RevisionCreated':
                return `Revision created ${formatDateForEvent(event.date)}`;
            case 'Document.Withdrawn':
                return `Withdrawn ${formatDateForEvent(event.date)}${  event.actionTakenBy ? ` by ${event.actionTakenBy}` : ''}`;
            case 'Document.Forwarded': {
                if (event.delegator) {
                    return `${event.actionTakenBy} Reassigned to ${
                        event.additionalText
                    } on behalf of ${event.delegator} ${formatDateForEvent(event.date)}`;
                }
                return `${event.actionTakenBy} Reassigned to ${
                    event.additionalText
                } ${formatDateForEvent(event.date)}`;
            }
            case 'Document.Discarded':
                return `Discarded ${formatDateForEvent(event.date)}`;
            case 'Document.RevisionReverted':
                return `Reverted ${formatDateForEvent(event.date)}`;
            case 'Document.AdhocApproverAdded': {
                if (event.delegator) {
                    return `${event.actionTakenBy} added ${
                        event.additionalText
                    } as approvers on behalf of ${event.delegator} ${formatDateForEvent(
                        event.date,
                    )}`;
                }
                return `${event.actionTakenBy} added ${
                    event.additionalText
                } as approvers ${formatDateForEvent(event.date)}`;
            }
            case 'Document.ReviewCompleted':
                return `Document reviewed by ${event.actionTakenBy} ${formatDateForEvent(
                    event.date,
                )}`;
            case 'Document.AssignedToDelegatee':
                return `Assigned to ${event.delegator} on behalf of ${
                    event.actionTakenBy
                } ${formatDateForEvent(event.date)} for Approving`;
            case 'Owner.InactiveApproverRemoved':
                return `${event.user1} removed by ${event.actionTakenBy} ${formatDateForEvent(
                    event.date,
                )}`;
            case 'Owner.InactiveApproverReassigned':
                return `${event.user1} replaced with ${event.user2} by ${
                    event.actionTakenBy
                } ${formatDateForEvent(event.date)}`;
            case 'Document.OwnerReplaced':
                return `Owner is changed from ${event.user1} to ${event.user2} by ${
                    event.actionTakenBy
                } ${formatDateForEvent(event.date)}`;
            case 'Document.OwnerChanged':
                return `Owner is changed from ${event.user1} to ${event.user2}`;
            case 'Document.ApprovalsCompleted':
                return `All approvals completed, waiting to be published`;
            case 'DCO.Followup':
                return `Followup email is sent ${formatDateForEvent(event.date)} to Approver`;
            case 'Document.DocAddedToDCO':
                return `${event.childDocNumber} is added to this DCO by ${event.actionTakenBy}`;
            default:
                return CONSTANTS.LOADING.TEXT;
        }
    };

    return (
        <div className="doc-detail-timeline">
            <VerticalTimeline animate className="v-timeline" layout="1-column">
                {timelineEvents.length > 0 &&
                    timelineEvents.map((t, i) => (
                        <VerticalTimelineElement
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            iconClassName={`${t.eventType.split('.')[1]}`}
                            className="vertical-timeline-element"
                        >
                            <p>
                                <SkeletonText text={getEventDescription(t)} width={100} count={2} />
                            </p>
                        </VerticalTimelineElement>
                    ))}
            </VerticalTimeline>
        </div>
    );
};

export default DocDetailEvents;
