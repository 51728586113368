/* eslint-disable no-restricted-syntax */
/* eslint-disable max-lines-per-function */
import React, { useMemo } from 'react';
import Modal from 'react-modal';
import { Column, useTable } from 'react-table';
import { PlusIconSvg } from '../../assets/images';
import { IDocumentActivity } from '../../interfaces/document';
import { IDocumentTypeInfo } from '../../interfaces/document-type';
import './ViewDifferenceModal.scss';

interface Props {
    show: boolean;
    handleClose: () => void;
    documentActivity: IDocumentActivity[];
    rowsSelected: number[];
    docTypeInfo: IDocumentTypeInfo;
}

type TransposedRow = {
    originalHeader: string;
    [key: string]: any;
};

const formatCellValue = (value: any) => {
    if (Array.isArray(value)) {
        return value.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>{item}</div>
        ));
    }
    return value;
};

const transposeData = (
    data: any[],
    selectedRowDetails: IDocumentActivity[],
): { columns: Column<TransposedRow>[]; data: TransposedRow[] } => {
    if (data.length === 0) return { columns: [], data: [] };

    const headers = Object.keys(data[0]);
    const transposedData: TransposedRow[] = headers.map((header) => {
        const row: TransposedRow = { originalHeader: header };
        data.forEach((d, index) => {
            row[`Column${index + 1}`] = formatCellValue(d[header]);
        });
        return row;
    });

    const columns: Column<TransposedRow>[] = [
        { Header: 'Field', accessor: 'originalHeader' },
        ...data.map((_, index) => ({
            Header: `${selectedRowDetails[index].actorName} at ${selectedRowDetails[index].actionTime}`,
            accessor: `Column${index + 1}`,
            Cell: ({ value }: { value: any }) => <div>{value}</div>,
        })),
    ];
    return { columns, data: transposedData };
};

const removeDuplicates = (array: IDocumentActivity[]) => {
    const uniqueIds = new Set<number>();
    return array.filter((item) => {
        if (uniqueIds.has(item.activityId)) {
            return false;
        }
        uniqueIds.add(item.activityId);
        return true;
    });
};

const ViewDifferenceModal: React.FC<Props> = ({
    show,
    handleClose,
    documentActivity,
    rowsSelected,
    docTypeInfo
}) => {
    const selectedKeys: (keyof IDocumentActivity)[] = useMemo(() => {
        if (docTypeInfo?.allowDCO) {
            return [
                'Title',
                'Description',
                'Owner',
                'Item Creator',
                'Version',
                'Category',
                'Attachments',
            ];
        }
        if (docTypeInfo?.isDCO) {
            return ['Title', 'Description', 'DCO Creator', 'Submitter', 'Documents', 'Attachments', 'Approvers'];
        }
        return ['Title', 'Description', 'Owner', 'Version', 'Attachments', 'Approvers'];
    }, [docTypeInfo]);

    const pickKeys = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> =>
        keys.reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {} as Pick<T, K>);

    const documentActivityFiltered = useMemo(
        () => removeDuplicates(documentActivity),
        [documentActivity],
    );

    const documentActivitySorted = useMemo(
        () => documentActivityFiltered.sort((a, b) => a.activityId - b.activityId),
        [documentActivityFiltered],
    );

    const selectedRowDetails = useMemo(
        () => documentActivitySorted.filter((row) => rowsSelected.includes(row.activityId)),
        [documentActivitySorted, rowsSelected],
    );

    const data = useMemo(
        () => selectedRowDetails.map((row) => pickKeys(row, selectedKeys)),
        [selectedRowDetails, selectedKeys],
    );

    const { columns, data: transposedData } = useMemo(
        () => transposeData(data, selectedRowDetails),
        [data],
    );

    const getRowProps = (row: any) => {
        const firstColumn = row.allCells[1]?.value;
        const secondColumn = row.allCells[2]?.value;   

        // Helper function to compare arrays deeply
        const deepEqual = (obj1: any, obj2: any) => {
            if (obj1 === obj2) return true;

            if (
                typeof obj1 !== 'object' ||
                typeof obj2 !== 'object' ||
                obj1 == null ||
                obj2 == null
            ) {
                return false;
            }

            const keys1 = Object.keys(obj1);
            const keys2 = Object.keys(obj2);

            if (keys1.length !== keys2.length) {
                return false;
            }

            for (const key of keys1) {
                if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }

            return true;
        };

        if (!firstColumn && secondColumn) {
            return {
                className: 'row-empty-to-value',
            };
        }

        if (firstColumn && secondColumn) {
            const isDifferent =
                Array.isArray(firstColumn) && Array.isArray(secondColumn)
                    ? !deepEqual(firstColumn, secondColumn)
                    : firstColumn !== secondColumn;

            if (isDifferent) {
                return {
                    className: 'row-modified-value',
                };
            }
        }

        return {};
    };

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: transposedData,
    });

    return (
        <Modal isOpen={show} className="AddToChange-Modal">
            <PlusIconSvg className="close-btn" onClick={handleClose} />
            <table className="viewdiffTable" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()} key={column.id}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps(getRowProps(row))} key={row.id}>
                                {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()} key={cell.column.id}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="modal-legend">
                <span className="newly-added-icon" />
                <span className="newly-added">Newly added</span>
                <span className="Modified-icon" />
                <span className="Modified">Modified</span>
                <span className="No-Change-icon" />
                <span className="No-Change">No Change</span>
            </div>
        </Modal>
    );
};

export default ViewDifferenceModal;
