import { IAPIResponseStatus, IOptionValue } from '../interfaces';
import { IDataTypeMapping } from '../interfaces/document';
import { getRequest } from '../utils/api-request';

const getAllDataTypes = async (): Promise<
    { apiStatus: IAPIResponseStatus; dataTypes: IOptionValue[] } | undefined
> => {
    try {
        const res = await getRequest(`/document-types/data-types`);
        if (res && res.apiStatus === 'SUCCESS') {
            const dataTypes: IOptionValue[] = res.response.map(
                (r: { name: string; id: number }) => ({
                    label: r.name,
                    value: r.id,
                }),
            );
            return { apiStatus: res.apiStatus, dataTypes };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getDataTypeUiMapping = async (): Promise<
    { apiStatus: IAPIResponseStatus; mapping: IDataTypeMapping[] } | undefined
> => {
    try {
        return {
            apiStatus: 'SUCCESS',
            mapping: [
                {
                    value: 1,
                    label: 'string',

                    uiControls: [
                        {
                            value: 1,
                            label: 'Text',
                        },
                        {
                            value: 2,
                            label: 'Text Box',
                        },
                        {
                            value: 5,
                            label: 'Single Select Dropdown',
                        },
                        {
                            value: 9,
                            label: 'Button Select',
                        },
                        {
                            value: 10,
                            label: 'Markup',
                        }
                    ],
                },
                {
                    value: 2,
                    label: 'integer',
                    uiControls: [
                        {
                            value: 7,
                            label: 'Number',
                        },
                    ],
                },
                {
                    value: 3,
                    label: 'date-time',
                    uiControls: [
                        {
                            value: 3,
                            label: 'DatePicker',
                        },
                        {
                            value: 13,
                            label: 'OnlyDate',
                        },
                    ],
                },
                {
                    value: 4,
                    label: 'string array',
                    uiControls: [
                        {
                            value: 6,
                            label: 'Multi Select Dropdown',
                        },
                        {
                            value: 8,
                            label: 'Text Box With Chip',
                        },
                    ],
                },
                {
                    value: 6,
                    label: 'boolean',
                    uiControls: [
                        {
                            value: 4,
                            label: 'Toggle',
                        },
                    ],
                },
                {
                    value: 7,
                    label: 'document',
                    uiControls: [
                        {
                            value: 11,
                            label: 'Table'
                        },
                        {
                            value: 12,
                            label: 'Reference'
                        }
                    ]
                },
                {
                    value: 8,
                    label: 'float',
                    uiControls: [
                        {
                            value: 14,
                            label: 'Float',
                        },
                    ],
                }
            ],
        };
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export { getAllDataTypes, getDataTypeUiMapping };
