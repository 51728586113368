import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { DocumentList, DocumentTypeConfig, TaskList, Delegations, PartyName } from '../../assets/images';

const handleNavbarBrandClick = () => {
    document.body.classList.remove('admin-side-navigation');
};

// eslint-disable-next-line max-lines-per-function
const AdminSideNavigation: React.FC = () => {
    const [footerMargin, setFooterMargin] = useState(0.0);
    const [breadCrumbsTotalHeight, setBreadcrumbsTotalHeight] = useState(0);
    const elementStyle: React.CSSProperties = {
        top: `-${breadCrumbsTotalHeight}px`,
        height: `calc(100% + ${footerMargin}px + ${breadCrumbsTotalHeight}px)`,
    };
    useEffect(() => {
        const footer = document.querySelector('.footer') as HTMLElement;
        const breadcrumbs = document.getElementsByClassName('breadcrumbs')[0] as HTMLElement;
        if (footer) {
            setFooterMargin(parseFloat(getComputedStyle(footer).marginTop));
        }
        if (breadcrumbs) {
            const computedStyle = getComputedStyle(breadcrumbs);
            setBreadcrumbsTotalHeight(breadcrumbs.offsetHeight + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom));
        }
    }, []);
    useEffect(() => {
        document.body.classList.add('admin-side-navigation');
        return () => {
            document.body.classList.remove('admin-side-navigation');
        };
    }, []);

    return (
        <div className="sidebar" style={elementStyle}>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/adm/documents" onClick={handleNavbarBrandClick}>
                        <DocumentList width="24px" height="24px" />
                        <span className="route-name">Documents</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/adm/document-types-config" onClick={handleNavbarBrandClick}>
                        <DocumentTypeConfig width="24px" height="24px" />
                        <span className="route-name">Document Types</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar className="bg-body-tertiary">
                <Container>
                    <Navbar.Brand as={Link} to="/adm/tasks?prev_page_type=ADMIN_TASKS" onClick={handleNavbarBrandClick}>
                        <TaskList width="24px" height="24px" />
                        <span className="route-name">Tasks</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar>
                <Container>
                    <Navbar.Brand as={Link} to="/adm/delegations" onClick={handleNavbarBrandClick}>
                        <Delegations width="24px" height="24px" />
                        <span className="route-name">Delegations</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Navbar>
                <Container>
                    <Navbar.Brand as={Link} to="/adm/partynames" onClick={handleNavbarBrandClick}>
                        <PartyName width="24px" height="24px" />
                        <span className="route-name">Party Names</span>
                    </Navbar.Brand>
                </Container>
            </Navbar>
        </div>
    );
};
export default AdminSideNavigation;
