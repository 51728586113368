/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react';
import ViewAttachments from '../../components/attachments/view/ViewAttachments';
import DocumentAction from '../../components/document-action/DocumentAction';
import { NoPermission } from '../../components/General';
import ImportantFields from '../../components/important-fields/ImportantFields';
import Reviewers from '../../components/reviewers/Reviewers';
import { ExternalStageCard, StageCard } from '../../components/work-flow-panel/WorkFlowPanel';
import TaskDetailProvider, { useTaskDetailContext } from '../../contexts/task-details-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import DocComments from '../../widgets/doc-comment/DocComments';
import DocumentInfo from '../../widgets/document-info/DocumentInfo';

import './TaskDetails.scss';
import { useDocumentContext } from '../../contexts/document-provider';
import ImportantFieldsForTask from '../../components/important-fields/ImportantFieldsForTask';
import ChecklistComponent from '../../components/checklist/checklistComponent';
import Toggle from '../../components/toggle/Toggle';
import ActivitySection from '../../components/activity-section/ActivitySection';
import ViewLinkedFiles from '../../components/input-fields/file-type-docs/ViewLinkedFiles';

// eslint-disable-next-line max-lines-per-function
const TaskDetails: React.FC = () => {
    const { user } = useAuthDataContext();
    const {
        docInfo,
        userType,
        fields,
        fieldsData,
        reviewers,
        approvers,
        approversDispatch,
        externalApprovers,
        documentId,
        activeDocVersion,
        isTaskActive,
        setActiveDocVersion,
        setDocInfo,
        fetchDocumentVersion,
        canViewTaskDetails,
        hideUsers,
        collectComment,
        collectCommentRejection,
        delegatorInfo,
        docTypeInfo,
        setHideUsers,
        taskId,
        attachmentConfig,
        isDocFetched,
        approverStatus,
        isGroupMember,
        accessibleByCurrentUser,
        publishOnceApproved,
        isSunsetEnabled,
        taskFieldsData,
        taskDocumentDispatch,
        activeVersionOwnerInfo,
        isLeadershipForm,
        activeVersionApprovalDeadline,
        setActiveVersionApprovalDeadline,
        isEndDateAsked,
        saveDeadlineData,
        externalApproversDispatch
    } = useTaskDetailContext();
    const {
        activeDocVersionId,
        relationArr,
        setRelationArr,
        latestrelationArr,
        setlatestrelationArr,
        existingContract,
    } = useDocumentContext();
    const docTypeInfo1 = useTaskDetailContext().docTypeInfo;
    const [unsavedComment, setUnsavedComment] = useState('');
    const setUnsavedCommentFunction = (comment: string) => {
        setUnsavedComment(comment);
    };

    const [activeTab, setActiveTab] = useState('Doc_Details');
    const [activeTabInHistory, setActiveTabInHistory] = useState('General');
    const isDocusignTriggered = approvers[0]?.approvers[0]?.docuSignTriggered
    ? approvers[0].approvers[0].docuSignTriggered
    : false;

    const [filesData] = taskFieldsData.filter((a) => a.name === 'file');
    return (
        <>
            {canViewTaskDetails || isGroupMember ? (
                <>
                    <DocumentInfo
                        pageType="TASK_DETAILS"
                        docInfo={docInfo}
                        documentId={documentId}
                        activeDocVersion={activeDocVersion}
                        latestDocVersionId={activeDocVersionId}
                        docVersions={[]}
                        setActiveDocVersion={setActiveDocVersion}
                        setDocInfo={setDocInfo}
                        onFavoriteClickHAndler={fetchDocumentVersion}
                        descError={false}
                        titleError={false}
                        effectiveFromError={false}
                        effectivePeriodError={false}
                        sunsetPeriodError={false}
                        docTypeInfo={docTypeInfo}
                        delegatorInfo={delegatorInfo}
                        publishOnceApproved={publishOnceApproved}
                        isSunsetEnabled={isSunsetEnabled}
                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                        activeVersionApprovalDeadline={activeVersionApprovalDeadline}
                        setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                        isEndDateAsked={isEndDateAsked}
                        relationArr={relationArr}
                        setRelationArr={setRelationArr}
                        latestrelationArr={latestrelationArr}
                        setlatestrelationArr={setlatestrelationArr}
                    />
                    <div className="task-details__section">
                        {docTypeInfo.storeAndShowActivity && <div className="tab">
                            <button
                                id="Doc_Details"
                                type="button"
                                className={`${activeTab === 'Doc_Details' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Doc_Details')}
                            >
                                <span className="name">
                                    {docTypeInfo.isDCO ? 'Affected Documents' : 'Details'}
                                </span>
                            </button>
                            <button
                                type="button"
                                id="Doc_History"
                                className={`${activeTab === 'Doc_History' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Doc_History')}
                            >
                                <span className="name">History</span>
                            </button>
                        </div>}
                        {activeTab === 'Doc_Details' && (
                            <div className={`${docTypeInfo.storeAndShowActivity ? 'main-section storeAndShowActivity' : 'main-section'}`}>
                                <div className="task-details">
                                    <h3>{docTypeInfo.isDCO ? 'Affected Documents' : 'Document Details'}</h3>
                                    <ImportantFieldsForTask
                                        isDisabled
                                        fields={fields}
                                        fieldsData={taskFieldsData}
                                        documentDispatch={taskDocumentDispatch}
                                    />
                                </div>
                                {docTypeInfo.allowFileTypeAttachment && filesData ?
                                    <ViewLinkedFiles filesData={filesData} /> :
                                    <ViewAttachments
                                        activeDocVersion={activeDocVersion.value as number}
                                        attachmentConfig={attachmentConfig}
                                        isDocFetched={isDocFetched}
                                        allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                    />
                                }
                                <ChecklistComponent isDisabled={true} />
                                {approvers.length > 0 && approverStatus !== 'REJECTED' && (
                                    <div className="approvers">
                                        {(approvers.length > 0 && approvers[0].approvers.length > 0) && (
                                            <h3>{docTypeInfo1.approversSectionTitle ? docTypeInfo1.approversSectionTitle : 'Approvers'}</h3>
                                        )}
                                        <div className="approvers-tab tab-data">
                                            { /* eslint-disable max-lines-per-function */
                                                approvers.map((approver, index) => (
                                                <>
                                                    {((isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isDCO) || approver.approvers.length > 0) ? (
                                                        <>
                                                            {docTypeInfo1.isContract && approver.scopeType === 'groupBased' ? (
                                                                <>
                                                                    <StageCard
                                                                        stageIndex={index}
                                                                        error={false}
                                                                        stageData={approver}
                                                                        approversDispatch={approversDispatch}
                                                                        setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                                        isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && (docTypeInfo.isDCO || (docTypeInfo.isContract && !isDocusignTriggered)))}
                                                                        // eslint-disable-next-line react/no-array-index-key
                                                                        key={index}
                                                                        cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                                        docTypeInfo={docTypeInfo}
                                                                        docInfo={docInfo}
                                                                        activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                                        saveFieldsData={saveDeadlineData}
                                                                    />
                                                                    {externalApprovers?.length ? <ExternalStageCard externalApprovers={ externalApprovers } isEditable={isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && docTypeInfo.isContract && !isDocusignTriggered}  docTypeInfo={docTypeInfo} externalApproversDispatch={externalApproversDispatch} docInfo={docInfo}/> : null}
                                                                </>
                                                            ) : (
                                                                <StageCard
                                                                    stageIndex={index}
                                                                    error={false}
                                                                    stageData={approver}
                                                                    approversDispatch={approversDispatch}
                                                                    setActiveVersionApprovalDeadline={setActiveVersionApprovalDeadline}
                                                                    isEditable={(isGroupMember && approvers[0].approvers[0].state === 'UNDER_REVIEW' && (docTypeInfo.isDCO || (docTypeInfo.isContract && !isDocusignTriggered)))}
                                                                    // eslint-disable-next-line react/no-array-index-key
                                                                    key={index}
                                                                    cardTitle={approver.approvalStageTitle ? approver.approvalStageTitle : 'Select Approvers'}
                                                                    docTypeInfo={docTypeInfo}
                                                                    docInfo={docInfo}
                                                                    activeVersionOwnerInfo={activeVersionOwnerInfo}
                                                                    saveFieldsData={saveDeadlineData}
                                                                />
                                                            )}
                                                        </>
                                                    ) : null}
                                                </>
                                                ))
                                                /* eslint-enable max-lines-per-function */
                                            }
                                        </div>
                                    </div>
                                )}
                                {reviewers.length > 0 && (
                                    <div className="reviewers">
                                        <h3>Reviewers</h3>
                                        <Reviewers isEditable={false} />
                                    </div>
                                )}
                                {userType !== 'OTHER' && isDocFetched && (
                                    <div className="comments">
                                        <DocComments
                                            activeDocVersion={activeDocVersion.value as number}
                                            setUnsavedCommentFunction={setUnsavedCommentFunction}
                                            allowReviseByAnyone={docTypeInfo.allowReviseByAnyone}
                                            userType={userType}
                                            pageType="TASK_DETAILS"
                                            approvers={approvers}
                                        />
                                    </div>
                                )}
                                {isTaskActive && (
                                    <DocumentAction
                                        docState={docInfo.docState}
                                        documentId={documentId}
                                        userType={userType}
                                        activeDocumentVersion={activeDocVersion.value as number}
                                        hideUsers={hideUsers}
                                        collectComment={collectComment}
                                        collectCommentRejection={collectCommentRejection}
                                        setHideUsers={setHideUsers}
                                        taskId={taskId}
                                        assigneeAndCreatorSame={
                                            !isLeadershipForm && user.id === docInfo.author.id
                                        }
                                        unsavedComment={unsavedComment}
                                        setUnsavedCommentFunction={setUnsavedCommentFunction}
                                        render={docInfo.render}
                                        assignee={docInfo.assignee}
                                        accessibleByCurrentUser={accessibleByCurrentUser}
                                        autoRenew={docInfo.autoRenew}
                                        docInfo={docInfo}
                                        setDocInfo={setDocInfo}
                                        isEndDateAsked={isEndDateAsked}
                                        isDocusignTriggered={approvers[0]?.approvers[0]?.docuSignTriggered ? approvers[0].approvers[0].docuSignTriggered : false}
                                    />
                                )}
                            </div>
                        )}

                        {activeTab === 'Doc_History' && docTypeInfo.storeAndShowActivity && (
                            <div className='history-tab'>
                                <div className="doc-history">
                                    <div className='doc-history-tab'>
                                        <button
                                            type="button"
                                            id="General"
                                            className={`${activeTabInHistory === 'General' ? 'active' : ''}`}
                                            onClick={() => setActiveTabInHistory('General')}
                                        >
                                            <span className="name">General</span>
                                        </button>
                                        {
                                            docTypeInfo.allowDCO && (<button
                                                type="button"
                                                id="Revisions"
                                                className={`${activeTabInHistory === 'Revisions' ? 'active' : ''}`}
                                                onClick={() => setActiveTabInHistory('Revisions')}
                                            >
                                                <span className="name">Revisions</span>
                                            </button>
                                            )}
                                    </div>
                                    {
                                        activeTabInHistory === 'General' && (
                                            <ActivitySection documentId={documentId} docTypeInfo={docTypeInfo} />
                                        )}
                                </div>
                            </div>
                        )}

                    </div>
                </>
            ) : (
                <NoPermission />
            )}
        </>
    );
};

const TaskDetailsPage: React.FC = () => (
    <div className="task-details">
        <TaskDetailProvider>
            <TaskDetails />
        </TaskDetailProvider>
    </div>
);

export default TaskDetailsPage;
