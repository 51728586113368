import React, { useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader-spinner';
import Modal from 'react-modal';
import { Link, useHistory } from 'react-router-dom';
import { Column, Row, usePagination, useSortBy, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { deleteCustomReport } from '../../API/reports';
import { DeleteSvg, PlusIconSvg, ReportHistory, SortIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import { IPaginationData, ISort, IStringObject } from '../../interfaces';
import { IReportsList } from '../../interfaces/reports';
import { DateTimeColumn, NoDocumentsFound } from '../General';
import { SkeletonText } from '../Skeleton';
import Pagination from '../pagination/Pagination';
import './ReportListTablePage.scss';

const columnMapping: IStringObject = {
    creator: 'creator',
    title: 'title',
};

const DEFAULT_SORT: ISort = {
    id: 'createdAt',
    desc: true,
};

const TitleReportColumn = (row: Row<IReportsList>) => {
    const {
        original: { title, id },
    } = row;
    return (
        <Link
            className="link-style"
            style={{ textDecoration: 'none' }}
            to={{
                pathname: '/reportList/report',
                search: `?report_id=${id}&report_name=${title}&prev_page_type=CUSTOM_REPORT`,
            }}
        >
            <SkeletonText text={title} width={200} />
        </Link>
    );
};

// eslint-disable-next-line max-lines-per-function
const ActionColumn = (row: Row<IReportsList>) => {
    const {
        original: { id, title },
    } = row;
    const [showDeleteModal, setDeleteModal] = useState(false);
    const [deleteReportLoading, setDeleteReportLoading] = useState(false);
    const history = useHistory();
    const deleteReport = async () => {
        setDeleteReportLoading(true);
        const res = await deleteCustomReport(id);
        if (res === 'SUCCESS') {
            toast.success('Report has been deleted successfully.');
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
        if (res === 'FAILURE') {
            toast.error('Unable to delete');
        }
        setDeleteReportLoading(false);
        setDeleteModal(false);
    };
    return (
        <div className="action-icons">
            {/* <DuplicateIcon
                style={{ cursor: 'pointer', marginRight: '10px' }}
                title="Duplicate Report"
            /> */}
            <ReportHistory
                style={{ cursor: 'pointer', marginRight: '10px' }}
                title="Report Summary"
                onClick={() => history.push(`/reportList/detail-summary?report_id=${id}&report_name=${title}`)}
            />
            <DeleteSvg
                style={{ cursor: 'pointer' }}
                title="Delete Report"
                onClick={() => setDeleteModal(true)}
            />
            <Modal
                isOpen={showDeleteModal}
                onRequestClose={() => setDeleteModal(false)}
                className="delete-attachment"
            >
                <PlusIconSvg className="close-btn" onClick={() => setDeleteModal(false)} />
                <div className="heading">
                    <h1>Are you sure you want to Delete</h1>
                    <h4>Selected report would be lost</h4>
                </div>
                <div className="action-buttons">
                    <button type="submit" onClick={() => setDeleteModal(false)}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="primary-btn submit-button"
                        onClick={() => deleteReport()}
                    >
                        <span className="submit">Delete</span>
                        {deleteReportLoading && (
                            <Loader
                                type="Oval"
                                color={CONSTANTS.BRAND_COLOR}
                                height={14}
                                width={14}
                            />
                        )}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

const VisibilityColumn = ({ value }: { value: string | undefined }) => (
    <div className={`status-pill ${value === 'Personal' ? 'personal' : 'shared'}`}>
        {value === 'Personal' ? 'Personal' : 'Shared'}
    </div>
);

const COLUMNS: Column<IReportsList>[] = [
    {
        Header: '#',
        id: 'index',
        accessor: (_row: any, i: number) => i + 1,
    },
    {
        Header: 'Title',
        accessor: 'title',
        Cell: ({ row }) => <TitleReportColumn {...row} />,
        minWidth: 300,
        disableSortBy: false,
    },
    {
        Header: 'Description',
        accessor: 'description',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 300,
        disableSortBy: true,
    },
    {
        Header: 'Creator',
        accessor: 'creator',
        Cell: ({ value }) => <SkeletonText text={value} width={75} />,
        minWidth: 250,
        disableSortBy: false,
    },
    // {
    //     Header: 'Visibility',
    //     accessor: 'visibility',
    //     Cell: ({ value }) => <VisibilityColumn value={value} />,
    //     minWidth: 250,
    //     disableSortBy: false,
    // },
    {
        Header: 'Creation Date',
        accessor: 'createdAt',
        Cell: ({ value }) => <DateTimeColumn value={value} />,
        minWidth: 200,
        disableSortBy: false,
    },
    {
        Header: 'Action',
        accessor: 'id',
        Cell: ({ row }) => <ActionColumn {...row} />,
        minWidth: 300,
        disableSortBy: true,
    },
];

interface Props {
    data: IReportsList[];
    pagination: IPaginationData;
    fetchReports: (
        pageIndex: number,
        pageSize: number,
        order: string,
        orderType: string | undefined,
    ) => void;
}

// eslint-disable-next-line max-lines-per-function
const CustomReportListTablePage: React.FC<Props> = ({ data, pagination, fetchReports }) => {
    const columns = useMemo(() => COLUMNS, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        previousPage,
        nextPage,
        setPageSize,
        pageOptions,
        state: { pageIndex, pageSize, sortBy },
        canPreviousPage,
        canNextPage,
    } = useTable<IReportsList>(
        {
            columns,
            data,
            manualPagination: true,
            disableSortRemove: true,
            manualSortBy: true,
            disableMultiSort: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize),
            initialState: {
                pageIndex: pagination.pageIndex,
                pageSize: pagination.pageSize,
                sortBy: [{ id: DEFAULT_SORT.id, desc: DEFAULT_SORT.desc }],
            },
        },
        useSortBy,
        usePagination,
    );

    useEffect(() => {
        fetchReports(
            pageIndex,
            pageSize,
            columnMapping[sortBy[0].id] ? columnMapping[sortBy[0].id] : 'createdAt',
            sortBy[0].desc ? 'DESC' : 'ASC',
        );
    }, [pageIndex, pageSize, sortBy]);
    return (
        <div className="reports-list-page-table">
            <div className="table-section">
                {page.length > 0 && (
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps(),
                                            )}
                                            {...column.getHeaderProps({
                                                style: {
                                                    minWidth: column.minWidth
                                                        ? column.minWidth
                                                        : 'auto',
                                                },
                                            })}
                                        >
                                            {column.render('Header')}
                                            <span>
                                                {column.isSorted && (
                                                    <SortIcon
                                                        className={`sort ${
                                                            column.isSortedDesc ? 'desc' : 'asc'
                                                        } `}
                                                    />
                                                )}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                            ? cell.column.minWidth
                                                            : 'auto',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {page.length === 0 && <NoDocumentsFound heading="No Data Found" />}
            </div>
            {page.length > 0 && pagination.totalCount > CONSTANTS.NO_OF_TABLE_ROWS && (
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    );
};

export default CustomReportListTablePage;
