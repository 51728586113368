import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getReportHistory } from '../../API/reports';
import CONSTANTS from '../../constants';
import { IPaginationData } from '../../interfaces';
import { IReportsHistory } from '../../interfaces/reports';
import ReportRunsTableTab from './RunsTab';

// eslint-disable-next-line max-lines-per-function
const RunsTabPage: React.FC = () => {
    const defaultRunsTableData = () => {
        const reportsHistoryData: IReportsHistory[] = [];
        Array.from({ length: 5 }).forEach(() => {
            reportsHistoryData.push({
                id: CONSTANTS.LOADING.NUMBER,
                reportId: CONSTANTS.LOADING.NUMBER,
                runBy: CONSTANTS.LOADING.TEXT,
                numberOfRecords: CONSTANTS.LOADING.NUMBER_WITH_0,
                runTime: CONSTANTS.LOADING.DATE,
                key: CONSTANTS.LOADING.TEXT,
            });
        });
        return reportsHistoryData;
    };
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: 10,
        totalCount: 0,
    };
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);
    const [data, setData] = useState(defaultRunsTableData);
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const reportId = queryParams.get('report_id');
    const [keyCheck, setKeyCheck] = useState(false);
    const update = async (
        pageIndex?: number,
        pageSize?: number,
        order?: string,
        orderType?: string | undefined,
    ) => {
        const size = pageSize !== undefined ? pageSize : pagination.pageSize;
        const index = pageIndex !== undefined ? pageIndex : pagination.pageIndex;
        const sortBy = order || 'runTime';
        const sortOrder = orderType || 'DESC';
        const params = {
            limit: size,
            offset: index * size,
            sortBy,
            sortOrder,
            reportId,
        };
        const reports = await getReportHistory(qs.stringify(params));
        if (reports?.apiStatus === 'SUCCESS') {
            setData(reports.reportHistory);
            setPagination({
                pageSize: size,
                pageIndex: index,
                totalCount: reports.total,
            });
            if (reports.reportHistory.length > 0 && reports.reportHistory[0].key === null) {
                setKeyCheck(true);
            } else {
                setKeyCheck(false);
            }
        }
    };
    const MINUTE_MS = 10000;
    useEffect(() => {
        const interval = setInterval(() => {
            if (keyCheck) {
                update(0, 10, 'runTime', 'DESC');
            }
        }, MINUTE_MS);
        return () => clearInterval(interval);
    }, [keyCheck]);
    return (
        <ReportRunsTableTab
            data={data}
            pagination={pagination}
            fetchReportHistory={(
                pageIndex: number,
                pageSize: number,
                order: string,
                orderType: string | undefined,
            ) => update(pageIndex, pageSize, order, orderType)}
        />
    );
};

export default RunsTabPage;
