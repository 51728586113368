import React, { useEffect } from 'react';
import {
    Redirect,
    Route,
    Switch,
    RouteProps,
    useLocation,
    useHistory,
    Link,
} from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import { useAuthDataContext } from './contexts/user-auth-provider';
import SignIn from './components/signIn/Auth';
import CodeVerify from './components/signIn/CodeVerify';
import CreateDocumentPage from './pages/create-document/CreateDocument';
import DocumentTypes from './pages/document-types/DocumentTypes';
import DocumentTypeCreate from './pages/document-types-create/DocumentTypeCreate';
import DocumentDetailsPage from './pages/document-details/DocumentDetails';
import FileDetailsPage from './pages/file-details/FileDetails';
import DashboardPage from './pages/dashboard/Dashboard';
import Search from './pages/search/Search';
import Settings from './pages/settings/Settings';
import TaskDetailsPage from './pages/task-details/TaskDetails';
import Documentation from './pages/documentation/Documentation';
import ReleaseNotes from './pages/release-notes/ReleaseNotes';
import CONSTANTS from './constants';
import { setLocalStorage } from './utils/local-storage';
import { useGAPageView } from './hooks/useGoogleAnalytics';
import DocumentExportPage from './pages/document-export/DocumentExport';
import CreateDocinDcoModal from './components/new-doc-main/CreateDocInDcoModal';
import AdminDashboard from './pages/admin-dashboard/AdminDashboard';
import AdminDocTypeTablePage from './pages/admin/AdminDocTypeTablePage';
import AdminDocTablePage from './pages/admin/AdminDocTablePage';
import ListAllTasksPage from './pages/list-all-tasks/ListAllTasksPage';
import AdminDelegations from './pages/admin-delegations/AdminDelegations';
import PartyNames from './pages/admin/party-names/PartyNames';
import ContractsDashboard from './pages/contracts/ContractsDashboard';
import ContractsPage from './pages/contracts/ContractsPage';
import NDAForm from './pages/nda/ndaForm';
import SeedStockAgreement from './pages/seed-stock-agreement/SeedStockAgreement';
import ReportsPage from './pages/reports/ReportsPage';
import ReportsListPage from './pages/reports/ReportsListPage';
import ReportDetailSummary from './components/reports/ReportDetailSummary';

interface PrivateProps extends RouteProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
}

const NotFound: React.FC = () => (
    <div className="not-found ">
        <div className="circle">
            <h1>404</h1>
        </div>
        <h3>Page Not Found</h3>
        <p>
            Go to <Link to="/dashboard">Dashboard</Link>
        </p>
    </div>
);

const Unauthorized: React.FC = () => (
    <div className="unauthorized">
        <div className="circle">
            <h1>401</h1>
        </div>
        <h3>Unauthorized</h3>
        <p>Please contact Admin</p>
    </div>
);

const PrivateRoute = ({ component, ...options }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1) {
            return <Route {...options} component={component} />;
        }

        if (tokenValid) {
            return <Redirect to="/404" />;
        }

        return <Redirect to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

const AdminRoute = ({ component, ...options }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1 && (user.isAdmin || user.isDcoAdmin)) {
            return <Route {...options} component={component} />;
        }

        if (tokenValid) {
            return <Redirect to="/401" />;
        }

        return <Redirect to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

const ClmRoute = ({ component, ...options }: PrivateProps) => {
    const { user, tokenValid, isLoading } = useAuthDataContext();
    if (!isLoading) {
        if (user.id && user.id !== -1 && (user.isAdmin || user.isClmAdmin)) {
            return <Route {...options} component={component} />;
        }

        if (tokenValid) {
            return <Redirect to="/401" />;
        }

        return <Redirect to="/signin" />;
    }
    return (
        <div className="puff-loader">
            <PuffLoader color={CONSTANTS.BRAND_COLOR} size={100} />
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
const Router1: React.FC = () => {
    const location = useLocation();
    const { triggerPageView } = useGAPageView();
    const history = useHistory();
    if (!['/signin', '/oauth/callback'].includes(location.pathname)) {
        setLocalStorage('path', location.pathname + location.search);
    }

    useEffect(() => {
        history.listen((loc) => {
            triggerPageView(loc);
        });
    }, [history, triggerPageView]);

    return (
        <Switch>
            <Route path="/signin" component={SignIn} />
            <Route path="/oauth/callback" component={CodeVerify} />
            <Route path="/404" component={NotFound} />
            <Route path="/401" component={Unauthorized} />

            <Redirect exact from="/" to="/dashboard" />
            <PrivateRoute path="/dashboard" component={DashboardPage} />
            <PrivateRoute path="/create-document" component={CreateDocumentPage} />
            <PrivateRoute path="/create-document-in-dco" component={CreateDocinDcoModal} />
            <AdminRoute exact path="/document-types" component={DocumentTypes} />
            <PrivateRoute exact path="/document-details" component={DocumentDetailsPage} />
            <PrivateRoute exact path="/file-details" component={FileDetailsPage} />
            <PrivateRoute exact path="/document-details/export" component={DocumentExportPage} />
            <PrivateRoute exact path="/task-details" component={TaskDetailsPage} />
            <AdminRoute exact path="/document-type/create" component={DocumentTypeCreate} />
            <PrivateRoute exact path="/search" component={Search} />
            <PrivateRoute exact path="/documentation" component={Documentation} />
            <PrivateRoute exact path="/release-notes" component={ReleaseNotes} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <AdminRoute exact path="/adm/dashboard" component={AdminDashboard} />
            <PrivateRoute exact path="/adm/document-types-config" component={AdminDocTypeTablePage} />
            <PrivateRoute exact path="/adm/documents" component={AdminDocTablePage} />
            <PrivateRoute exact path="/adm/tasks" component={ListAllTasksPage} />
            <PrivateRoute exact path="/adm/delegations" component={AdminDelegations} />
            <PrivateRoute exact path="/adm/partynames" component={PartyNames} />
            <ClmRoute exact path="/clm/dashboard" component={ContractsDashboard} />
            <ClmRoute exact path="/clm/contracts" component={ContractsPage} />
            <PrivateRoute exact path="/clm/nda-form" component={NDAForm} />
            <PrivateRoute exact path="/clm/seed-stock-agreement" component={SeedStockAgreement} />
            <PrivateRoute exact path="/reportList" component={ReportsListPage} />
            <PrivateRoute exact path="/reportList/report" component={ReportsPage} />
            <PrivateRoute exact path="/reportList/detail-summary" component={ReportDetailSummary} />
        </Switch>
    );
};

const Router: React.FC = () => {
    const validRoutes = [
        '/',
        '/dashboard',
        '/signin',
        '/oauth/callback',
        '/404',
        '/401',
        '/create-document',
        '/document-types',
        '/document-details',
        '/file-details',
        '/task-details',
        '/document-type/create',
        '/search',
        '/settings',
        '/documentation',
        '/release-notes',
        '/document-details/export',
        '/create-document-in-dco',
        '/adm/dashboard',
        '/adm/document-types-config',
        '/adm/documents',
        '/adm/tasks',
        '/adm/delegations',
        '/adm/partynames',
        '/clm/dashboard',
        '/clm/contracts',
        '/clm/nda-form',
        '/clm/seed-stock-agreement',
        '/reportList',
        '/reportList/report',
        '/reportList/detail-summary',
    ];
    const location = useLocation();
    if (!validRoutes.includes(location.pathname)) {
        return <Redirect to="/404" />;
    }
    return <Router1 />;
};

export default Router;
