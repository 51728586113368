/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/react-in-jsx-scope */
import Skeleton from 'react-loading-skeleton';
import CONSTANTS from '../constants';

interface ISkeletonTextProps {
    text?: number | string;
    width: number | string;
    count?: number;
}

const SkeletonText = ({ text, width, count = 1 }: ISkeletonTextProps): JSX.Element => (
    <>
        {text === CONSTANTS.LOADING.TEXT || text === CONSTANTS.LOADING.NUMBER ? (
            <Skeleton width={width} count={count} />
        ) : (
            text
        )}
    </>
);

const SkeletonDate = ({ text, width, count }: ISkeletonTextProps): JSX.Element => (
    <>{text === CONSTANTS.LOADING.TEXT ? <Skeleton width={width} count={count} /> : text}</>
);

export { SkeletonText, SkeletonDate };
