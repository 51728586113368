import React from 'react'
import { IField } from '../../../interfaces/document'
import { IDocumentAction } from '../../../reducers/document-reducer'
import Toggle from '../../toggle/Toggle';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: boolean;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    error: boolean;
}

const handleToggleClick = function(fieldName: string,toggleValue: boolean, documentDispatch: React.Dispatch<IDocumentAction> | null)
{
    if(documentDispatch)
    {
        if(fieldName === 'specificBusinessPurpose' && toggleValue)
        {
            documentDispatch({
                type: 'toggle',
                inputName: fieldName,
                value: toggleValue,
            });
            documentDispatch({
                type: 'toggle',
                inputName: 'nonStandardTemplate',
                value: toggleValue,
            });
        }
        else{
            documentDispatch({
                type: 'toggle',
                inputName: fieldName,
                value: toggleValue,
            })
        }
    }
}

const ToggleInput: React.FC<Props> = ({
    documentDispatch,
    field,
    isDisabled,
    value,
    error,
}: Props) => (
    <div className={`${error ? 'error' : ''}`}>
        <Toggle
            isDisabled={isDisabled}
            isChecked={value}
            onclick={() =>
                handleToggleClick(field.name,!value,documentDispatch)
            }
        />
    </div>
);

export default ToggleInput
