import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { getDynamicValues } from '../../../API/fields';
import CONSTANTS from '../../../constants';
import { useDocumentContext } from '../../../contexts/document-provider';
import { IOptionValue } from '../../../interfaces';

import { IField } from '../../../interfaces/document';
import { IDocumentAction } from '../../../reducers/document-reducer';
import './SingleSelect.scss';

interface Props {
    field: IField;
    isDisabled: boolean;
    value: string[];
    error: boolean;
    documentDispatch: React.Dispatch<IDocumentAction> | null;
}

// eslint-disable-next-line max-lines-per-function
const SingleSelect: React.FC<Props> = ({
    field,
    documentDispatch,
    isDisabled,
    value,
    error,
}: Props) => {
    const { docInfo, setDocInfo } = useDocumentContext();
    // const selectedValue = field.selectOption?.filter((s) => s.value.toString() === value);
    const [loading, setLoading] = useState(false);

    let staticSingleSelectFlag = false;
    if(field.selectType === "static" && field.uiControl.label === "Single Select Dropdown") {
        staticSingleSelectFlag = true;
    };
    
    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (field.selectType === 'dynamic') {
                if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                    const dynamicOptions = await getDynamicValues(field.id, inputValue);
                    if (dynamicOptions?.apiStatus === 'SUCCESS') {
                        resolve(dynamicOptions.dynamicData);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            } else if (field.selectType === 'static') {
                if(field.selectOption) {
                    const filteredOptions = field.selectOption.filter(option => option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()));
                    resolve(filteredOptions);
                }
                resolve(field.selectOption ? field.selectOption : []);
                setLoading(false);
            }
        });
    };
    return (
        <AsyncSelect
            name="single-select"
            id="single-select"
            isMulti={false}
            className={`react-select ${error ? 'error' : ''}`}
            classNamePrefix="select"
            isLoading={loading}
            loadOptions={promiseOptions}
            value={value}
            getOptionLabel={(e) =>
                value.length > 0 && !staticSingleSelectFlag ? `${e.label} <${e.value}>` : e?.label
            }
            defaultOptions={staticSingleSelectFlag && field.selectOption}
            isDisabled={isDisabled}
            placeholder={isDisabled ? '' : 'Start Typing...'}
            onChange={(option) => {
                if (option && documentDispatch) {                    
                    documentDispatch({
                        type: 'single-select-dropdown',
                        inputName: field.name,
                        value: [option] as IOptionValue[],
                    });
                    
                    if(field.name === 'employeeName') {
                        setDocInfo({ ...docInfo, title: `PRB Nomination - ${option.label}`, description: `PRB Nomination - ${option.label}` })
                    }
                }
            }}
        />
    );
};

export default SingleSelect;
