/* eslint-disable no-debugger */
/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import qs from 'qs';

import { IAdditionalSearch, IAPIResponseStatus, IOptionValue, IStringObject } from '../interfaces';
import {
    IMyApproval,
    IMyDocument,
    IMyReviews,
    IMyFavorites,
    ISearch,
    IHighlighted,
    ILatestDocuments,
    IGroupApproval,
    IDCO,
} from '../interfaces/dashboard';
import { IFilterConfig } from '../interfaces/filters';
import { convertToEpoch, getDocVersion, getUserFullName } from '../utils';
import { deleteRequest, getRequest, postRequest } from '../utils/api-request';
import { convertEpochToDate } from '../utils/date';
import { getActiveDelegators } from './delegation';
import { getPublishedDocumentTypes } from './document-type';
import { IFileTypeDoc } from '../interfaces/attachment';

interface ITaskVariables {
    name: string;
    value: string;
    operator: 'eq';
}

const getAllCount = async (
    queryParams: IStringObject
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        MyDocsCount: number;
        MyLatestCount: number;
        MyFavoriteCount: number;
        MyApprovalCount: number;
        MyReviewCount: number;
        GroupApprovalcount: number;
        render: boolean;
    }
    | undefined
> => {
    try {
        const body: {
            [key: string]: any;
        } = {};
        const taskVariables: ITaskVariables[] = [];
        const queryKeys = Object.keys(queryParams);

        for (const key of queryKeys) {
            if (queryParams[key]) {
                taskVariables.push({
                    name: key,
                    value: queryParams[key],
                    operator: 'eq',
                });
            }
        }

        if (taskVariables.length > 0) {
            body.taskVariables = taskVariables;
        }
        if (queryParams.groupName) {
            body.filteredGroupId = queryParams.groupName;
        }
        const isCount = true;
        const response = await postRequest(
            `documents/get-all-count?giveCount=${isCount}`,
            body,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const MyDocumentCount = response.response.MyDocsCount ? response.response.MyDocsCount : 0;
            const MyLatestDocCount = response.response.MyLatestCount ? response.response.MyLatestCount : 0;
            const MyFavoriteDocCount = response.response.MyFavoriteCount ? response.response.MyFavoriteCount : 0;
            const MyApprovalDocCount = response.response.MyApprovalCount ? response.response.MyApprovalCount : 0;
            const MyReviewDocCount = response.response.MyReviewCount ? response.response.MyReviewCount : 0;
            const GroupApprovalDoccount = response.response.GroupApproval.count ? response.response.GroupApproval.count : 0;

            return {
                apiStatus: response.apiStatus,
                MyDocsCount: MyDocumentCount,
                MyLatestCount: MyLatestDocCount,
                MyFavoriteCount: MyFavoriteDocCount,
                MyApprovalCount: MyApprovalDocCount,
                MyReviewCount: MyReviewDocCount,
                GroupApprovalcount: GroupApprovalDoccount,
                render: response.response.GroupApproval.render
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return {
                apiStatus: response.apiStatus,
                MyDocsCount: 0,
                MyLatestCount: 0,
                MyFavoriteCount: 0,
                MyApprovalCount: 0,
                MyReviewCount: 0,
                GroupApprovalcount: 0,
                render: false
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};
// eslint-disable-next-line max-lines-per-function
const getMyApprovals = async (
    queryParams: IStringObject,
    userId: number,
    sorting: {
        sortBy: string;
        sortOrder: 'desc' | 'asc';
    },
    limit = 10,
    offset = 0,
): Promise<
    | { apiStatus: IAPIResponseStatus; myApprovals: IMyApproval[]; totalDocuments: number }
    | undefined
> => {
    try {
        const body: {
            [key: string]: any;
        } = {
            sorting: [sorting],
        };
        const taskVariables: ITaskVariables[] = [];
        const queryKeys = Object.keys(queryParams);
        for (const key of queryKeys) {
            if (queryParams[key]) {
                if (key === 'startedStart') {
                    body.startedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'startedEnd') {
                    body.startedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedStart') {
                    body.finishedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedEnd') {
                    body.finishedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else {
                    taskVariables.push({
                        name: key,
                        value: queryParams[key],
                        operator: 'eq',
                    });
                }
            }
        }
        const activeDelegators = await getActiveDelegators(userId);


        if (taskVariables.length > 0) {
            body.taskVariables = taskVariables;
        }
        const response = await postRequest(
            `tasks/approval-task-list-2/me?maxResults=${limit}&firstIndex=${offset}`,
            body,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const myApprovals: IMyApproval[] = [];

            const documents = response.response.tasks;
            const totalDocuments = response.response.count ? response.response.count : 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const task of documents) {
                const deadlineReference = task?.deadlineReference;
                const deadline = task?.deadline;
                const deadlineEpochs = deadlineReference * 1000 + deadline * 24 * 60 * 60 * 1000;
                myApprovals.push({
                    taskId: task.taskId,
                    createdDate: new Date(task.startTime),
                    deadline: deadlineReference && deadline ? new Date(deadlineEpochs) : 'N/A',
                    authorId: task.document?.owner?.id ? task.document?.owner.id : 0,
                    authorName: task.document?.owner?.id
                        ? getUserFullName(
                            task.document.owner.firstName,
                            task.document.owner.lastName,
                        )
                        : '',
                    docId: task.document?.id ? task.document.id : 0,
                    docNumber: task.document?.documentNumber ? task.document.documentNumber : '',
                    files: task.document?.attachments ? task.document.attachments.length : 0,
                    title: task.document?.title ? task.document.title : '',
                    approverState: task.approverState,
                    assigneeId: parseInt(task.assigneeId, 10),
                    activeDelegators: activeDelegators || [],
                    isConfidential: task.document?.isConfidential,
                });
            }

            return {
                apiStatus: response.apiStatus,
                myApprovals,
                totalDocuments,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myApprovals: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getMyReviews = async (
    queryParams: IStringObject,
    userId: number,
    sorting: {
        sortBy: string;
        sortOrder: 'desc' | 'asc';
    },
    limit = 10,
    offset = 0,
): Promise<
    { apiStatus: IAPIResponseStatus; myReviews: IMyReviews[]; totalDocuments: number } | undefined
> => {
    try {
        const body: {
            [key: string]: any;
        } = { sorting: [sorting] };
        const taskVariables: ITaskVariables[] = [];
        const queryKeys = Object.keys(queryParams);
        for (const key of queryKeys) {
            if (queryParams[key]) {
                if (key === 'startedStart') {
                    body.startedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'startedEnd') {
                    body.startedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedStart') {
                    body.finishedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedEnd') {
                    body.finishedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else {
                    taskVariables.push({
                        name: key,
                        value: queryParams[key],
                        operator: 'eq',
                    });
                }
            }
        }
        if (taskVariables.length > 0) {
            body.taskVariables = taskVariables;
        }

        const activeDelegators = await getActiveDelegators(userId);

        const response = await postRequest(
            `tasks/review-task-list-2/me?maxResults=${limit}&firstIndex=${offset}`,
            body,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const myReviews: IMyReviews[] = [];

            const documents = response.response.tasks;
            const totalDocuments = response.response.count ? response.response.count : 0;
            // eslint-disable-next-line no-restricted-syntax
            for (const task of documents) {
                myReviews.push({
                    taskId: task.taskId,
                    createdDate: new Date(task.startTime),
                    authorId: task.document?.owner?.id ? task.document?.owner.id : 0,
                    authorName: task.document?.owner?.id
                        ? getUserFullName(
                            task.document.owner.firstName,
                            task.document.owner.lastName,
                        )
                        : '',
                    docId: task.document?.id ? task.document.id : 0,
                    docNumber: task.document?.documentNumber ? task.document.documentNumber : '',
                    files: task.document?.attachments ? task.document.attachments.length : 0,
                    title: task.document?.title ? task.document.title : '',
                    approverState: task.approverState,
                    assigneeId: parseInt(task.assigneeId, 10),
                    activeDelegators: activeDelegators || [],
                    isConfidential: task.document?.confidential,
                });
            }

            return {
                apiStatus: response.apiStatus,
                myReviews,
                totalDocuments,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myReviews: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getMyDocuments = async (
    queryParams: IStringObject,
): Promise<
    | { apiStatus: IAPIResponseStatus; myDocuments: IMyDocument[]; totalDocuments: number }
    | undefined
> => {
    const body: {
        [key: string]: any;
    } = {};
    const queryKeys = Object.keys(queryParams);
    for (const key of queryKeys) {
        if (queryParams[key]) {
            if (
                [
                    'createdAtStart',
                    'approvedOnStart',
                    'submittedOnStart',
                    'createdAtEnd',
                    'approvedOnEnd',
                    'submittedOnEnd',
                ].includes(key)
            ) {
                body[key] = (convertToEpoch(new Date(queryParams[key])) / 1000).toString();
            } else {
                body[key] = queryParams[key];
            }
        }
    }
    try {
        const response = await getRequest(`documents/my-documents?${qs.stringify(body)}`);
        if (response?.apiStatus === 'SUCCESS') {
            const myDocuments: IMyDocument[] = [];

            const documents = response.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const document of documents) {
                myDocuments.push({
                    docId: document.id,
                    title: document.title,
                    docNumber: document.documentNumber,
                    files: (document.activeDocumentVersion?.attachmentCount ?? 0) + (document.activeDocumentVersion?.data?.file?.length ?? 0),
                    status: document.activeDocumentVersion.activeVersionState,
                    createdDate: convertEpochToDate(document.createdAt),
                    approvedOn: document.activeDocumentVersion?.approvedOn
                        ? convertEpochToDate(document.activeDocumentVersion?.approvedOn)
                        : new Date('N/A'),
                    isFavorite: !!document.isFavorite,
                    isConfidential: !!document.confidential,
                    versionOwnerId: document.activeDocumentVersion.versionOwnerId
                });
            }

            return {
                apiStatus: response.apiStatus,
                myDocuments,
                totalDocuments: response.response.count,
            };
        }
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myDocuments: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getAllDraftDCOs = async (
    queryParams: IStringObject
): Promise<
    | { apiStatus: IAPIResponseStatus; DraftDCOs: IDCO[]; totalDCOs: number }
    | undefined
> => {
    try {
        const response = await getRequest(`documents/getAllDraftDCOs?${qs.stringify(queryParams)}`);
        if (response?.apiStatus === 'SUCCESS') {
            const DraftDCOs: IDCO[] = [];

            const documents = response.response.rows;
            for (const document of documents) {
                DraftDCOs.push({
                    docId: document.id,
                    title: document.title,
                    docNumber: document.documentNumber,
                    docVersionId: document.activeVersionId,
                    state: document.state,
                    data: document.documentVersion[0].data
                });
            }

            return {
                apiStatus: response.apiStatus,
                DraftDCOs,
                totalDCOs: response.response.count,
            };
        }
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, DraftDCOs: [], totalDCOs: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

const getAllFileTypeDocs = async (
    queryParams: IStringObject
): Promise<
    | { apiStatus: IAPIResponseStatus; fileTypeDocs: IFileTypeDoc[]; totalFiles: number }
    | undefined
> => {
    try {
        const response = await getRequest(`documents/file-type-docs`);
        // ?${qs.stringify(queryParams)}`);
        if (response?.apiStatus === 'SUCCESS') {
            const fileTypeDocs: IFileTypeDoc[] = [];
            const documents = response.response.rows;
            /* eslint-disable no-plusplus */
            for (let idx = 0; idx < documents.length; idx++) {
                const document = documents[idx]
                const docAttachmentObj = document?.documentVersion[0]?.documentAttachment[0];
                const releasedOn = document?.documentVersion[0]?.approvedOn ? document?.documentVersion[0]?.approvedOn : 'N/A';
                fileTypeDocs.push({
                    id: document?.id,
                    parentDocId: Number(document?.documentVersion[0]?.data?.parentDocId),
                    docVersionId: document?.documentVersion[0]?.id,
                    attachmentId: document?.documentVersion[0]?.documentAttachment[0]?.id,
                    attachmentType: docAttachmentObj?.metaData?.attachmentType,
                    displayName: docAttachmentObj?.metaData?.displayName,
                    extension: docAttachmentObj?.metaData?.extension,
                    mimeType: docAttachmentObj?.metaData?.mimeType,
                    name: docAttachmentObj?.metaData?.name,
                    size: docAttachmentObj?.metaData?.size,
                    fileNumber: document?.documentNumber,
                    title: document?.title,
                    description: document?.title,
                    isPrimary: false,
                    uploadedOn: docAttachmentObj?.createdAt,
                    releasedOn
                });
            }

            return {
                apiStatus: response.apiStatus,
                fileTypeDocs,
                totalFiles: response.response.count,
            };
        }
        return { apiStatus: response?.apiStatus ? response?.apiStatus : 'FAILURE', fileTypeDocs: [], totalFiles: 0 };
    } catch (err) {
        return undefined;
    }
};

const getLatestDocuments = async (
    queryParams: string,
): Promise<
    | { apiStatus: IAPIResponseStatus; latestDocuments: ILatestDocuments[]; totalDocuments: number }
    | undefined
> => {
    try {
        const response = await getRequest(`documents/latest-documents?${queryParams}`);
        if (response?.apiStatus === 'SUCCESS') {
            const latestDocuments: ILatestDocuments[] = [];

            const documents = response.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const document of documents) {
                latestDocuments.push({
                    authorId: document.ownerId,
                    authorName: document.owner
                        ? getUserFullName(document.owner.firstName, document.owner.lastName)
                        : '',
                    createdDate: convertEpochToDate(document.createdAt),
                    docId: document.id,
                    docNumber: document.documentNumber,
                    files: (document.activeDocumentVersion?.attachmentCount ?? 0) + (document.activeDocumentVersion?.data?.file?.length ?? 0),
                    status: document.state,
                    title: document.title,
                    isConfidential: document.confidential,
                    isFavorite: document.isFavorite,
                    updatedAt: convertEpochToDate(document.updatedAt),
                });
            }

            return {
                apiStatus: response.apiStatus,
                latestDocuments,
                totalDocuments: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, latestDocuments: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

// eslint-disable-next-line max-lines-per-function
const getMyFavorites = async (
    queryString: string,
): Promise<
    | { apiStatus: IAPIResponseStatus; myFavorites: IMyFavorites[]; totalDocuments: number }
    | undefined
> => {
    try {
        const response = await getRequest(`documents/favorite?${queryString}`);
        if (response?.apiStatus === 'SUCCESS') {
            const myFavorites: IMyFavorites[] = [];

            const documents = response.response.rows;
            // eslint-disable-next-line no-restricted-syntax
            for (const document of documents) {
                myFavorites.push({
                    authorId: document.ownerId,
                    authorName: document.owner
                        ? getUserFullName(document.owner.firstName, document.owner.lastName)
                        : '',
                    createdDate: convertEpochToDate(document.createdAt),
                    docId: document.id,
                    docNumber: document.documentNumber,
                    files: (document.attachmentCount ?? 0) + (document.activeDocumentVersion?.data?.file?.length ?? 0),
                    status: document.state,
                    title: document.title,
                    isConfidential: document.confidential,
                });
            }

            return {
                apiStatus: response.apiStatus,
                myFavorites,
                totalDocuments: response.response.count,
            };
            // eslint-disable-next-line no-else-return
        } else if (response?.apiStatus === 'FAILURE') {
            return { apiStatus: response.apiStatus, myFavorites: [], totalDocuments: 0 };
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const getGroupApprovals = async (
    queryParams: IStringObject,
    sorting: {
        sortBy: string;
        sortOrder: 'desc' | 'asc';
    },
    limit = 10,
    offset = 0,
    giveCount?: boolean
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        groupApprovals: IGroupApproval[];
        totalDocuments: number;
        render: boolean;
    }
    | undefined
> => {
    try {
        const body: {
            [key: string]: any;
        } = {
            sorting: [sorting],
        };
        const taskVariables: ITaskVariables[] = [];
        const queryKeys = Object.keys(queryParams);
        for (const key of queryKeys) {
            if (queryParams[key]) {
                if (key === 'startedStart') {
                    body.startedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'startedEnd') {
                    body.startedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedStart') {
                    body.finishedAfter = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else if (key === 'finishedEnd') {
                    body.finishedBefore = convertToEpoch(new Date(queryParams[key])) / 1000;
                } else {
                    taskVariables.push({
                        name: key,
                        value: queryParams[key],
                        operator: 'eq',
                    });
                }
            }
        }
        if (taskVariables.length > 0) {
            body.taskVariables = taskVariables;
        }
        if (queryParams.groupName) {
            body.filteredGroupId = queryParams.groupName;
        }
        if (giveCount) {
            const response = await postRequest(
                `tasks/group-approval-task-list-2/me?maxResults=${limit}&firstIndex=${offset}&giveCount=${giveCount}`,
                body,
            );
            if (response?.apiStatus === 'SUCCESS') {
                const totalDocuments = response.response.count ? response.response.count : 0;
                return {
                    apiStatus: response.apiStatus,
                    groupApprovals: [],
                    totalDocuments,
                    render: response.response.render,
                };
            }
            // eslint-disable-next-line no-else-return
            else if (response?.apiStatus === 'FAILURE') {
                return {
                    apiStatus: response.apiStatus,
                    groupApprovals: [],
                    totalDocuments: 0,
                    render: false,
                };
            }
            return undefined;
        }
        const response = await postRequest(
            `tasks/group-approval-task-list-2/me?maxResults=${limit}&firstIndex=${offset}`,
            body,
        );
        if (response?.apiStatus === 'SUCCESS') {
            const { tasks } = response.response;
            const totalDocuments = response.response.count ? response.response.count : 0;
            const groupApprovals: IGroupApproval[] = [];
            for (const task of tasks) {
                // const deadlineReference = task?.deadlineReference;
                // const deadline = task?.deadline;
                // const deadlineEpochs = deadlineReference*1000 + deadline*24*60*60*1000;

                groupApprovals.push({
                    taskId: task.taskId,
                    createdDate: new Date(task.startTime),
                    // deadline: deadlineReference && deadline ? new Date(deadlineEpochs) : 'N/A',
                    authorId: task.document?.owner?.id ? task.document?.owner.id : 0,
                    authorName: task.document?.owner?.id
                        ? getUserFullName(
                            task.document.owner.firstName,
                            task.document.owner.lastName,
                        )
                        : '',
                    docId: task.document?.id ? task.document.id : 0,
                    docNumber: task.document?.documentNumber ? task.document.documentNumber : '',
                    files: task.document?.attachments ? task.document.attachments.length : 0,
                    title: task.document?.title ? task.document.title : '',
                    approverState: task.approverState,
                    approver: task.approver,
                    assigneeId: parseInt(task.assigneeId, 10),
                    isConfidential: task.document?.isConfidential,
                    groupName: task.groupName,
                });
            }
            return {
                apiStatus: response.apiStatus,
                groupApprovals,
                totalDocuments,
                render: response.response.render,
            };
        }
        // eslint-disable-next-line no-else-return
        else if (response?.apiStatus === 'FAILURE') {
            return {
                apiStatus: response.apiStatus,
                groupApprovals: [],
                totalDocuments: 0,
                render: false,
            };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const getFavoriteDocIds = async (): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        documentIds: number[];
    }
    | undefined
> => {
    try {
        const res = await getRequest(`documents/list-favorite`);
        if (res) {
            return { apiStatus: res.apiStatus, documentIds: res.response };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const addToFavorites = async (
    documentId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await postRequest(`documents/favorite`, { documentId });
        if (res?.apiStatus) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const removeFromFavorites = async (
    documentId: number,
): Promise<{ apiStatus: IAPIResponseStatus } | undefined> => {
    try {
        const res = await deleteRequest(`documents/favorite?documentId=${documentId}`);
        if (res?.apiStatus) {
            return { apiStatus: res.apiStatus };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const facetConfigFun = (
    key: string,
): { displayName: string; name: string; placeholder: string } => {
    if (key === 'author') {
        return {
            displayName: 'Author',
            name: 'owner.name',
            placeholder: 'Start Typing Author Name',
        };
    }
    if (key === 'documentNumber') {
        return {
            displayName: 'Document Number',
            name: 'documentNumber',
            placeholder: 'Start Typing Document Number',
        };
    }
    if (key === 'documentTypeName') {
        return {
            displayName: 'Document Type',
            name: 'index',
            placeholder: 'Start Typing Document Type',
        };
    }
    if (key === 'state') {
        return {
            displayName: 'Document State',
            name: 'state',
            placeholder: 'Select State',
        };
    }
    return {
        displayName: key,
        name: key,
        placeholder: '',
    };
};

// eslint-disable-next-line max-lines-per-function
const fecthDirectfromDb = async (
    searchFieldname: string,
    searchFieldvalue: string,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        docId: number;
        searchDocuments: ISearch[];
        facets: IFilterConfig[];
        totalCount: number;
    }
    | undefined
> => {
    try {
        if (searchFieldname === 'documentNumber') {
            const res = await getRequest(
                `documents/fetchDocumentdirectfromDb?fieldType=${searchFieldname}&fieldValue=${searchFieldvalue}`,
            );
            if (res?.apiStatus === 'SUCCESS') {
                const resBody = res.response;
                if (resBody && resBody?.id !== null) {
                    return {
                        apiStatus: res.apiStatus,
                        docId: resBody.id,
                        searchDocuments: [],
                        facets: [],
                        totalCount: 0,
                    };
                }
                return undefined; // in case of no id
            }
            return undefined; // in case of failure response
        }
        return undefined; // default return
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

const promiseDocTypeOptions = async (inputValue: string): Promise<Map<string, string>> =>
    // eslint-disable-next-line no-async-promise-executor, no-new
    new Promise(async (resolve) => {
        const res = await getPublishedDocumentTypes(inputValue);
        const docTypeMap = new Map();
        if (res?.apiStatus === 'SUCCESS') {
            const { docTypeCard } = res;
            // eslint-disable-next-line no-restricted-syntax
            for (const d of docTypeCard) {
                docTypeMap.set(d.name, d.code);
                // docTypes.push({ label: d.name, value: d.code });
            }
            resolve(docTypeMap);
        }
    });

// eslint-disable-next-line max-lines-per-function
const getSearchDocuments = async (
    query: string,
    advancedSearch: IAdditionalSearch[],
    searchInAttachment: boolean,
    docTypeSelect: IOptionValue | undefined,
    sortOption: string,
    sortOrder: string,
    documentType?: string,
    size = 10,
    from = 0,
): Promise<
    | {
        apiStatus: IAPIResponseStatus;
        searchDocuments: ISearch[];
        facets: IFilterConfig[];
        totalCount: number;
    }
    | undefined
> => {
    try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const body: { [key: string]: any } = { from, size, searchInAttachment };

        if (query) {
            body.query = query;
        }
        if (docTypeSelect) {
            body.index = docTypeSelect.value;
        }
        if (documentType) {
            body.index = documentType;
        }
        if (sortOption) {
            body.sortOption = sortOption;
        }
        if (sortOrder) {
            body.sortOrder = sortOrder;
        }
        if (advancedSearch.length > 0) {
            body.fields = await Promise.all(
                advancedSearch.map((adv) => {
                    const temp = { ...adv };
                    // console.log(`advance filter ${adv.name} and ${adv.value}`);
                    if (
                        adv.name === 'Custom Date RangeStart' ||
                        adv.name === 'Custom Date RangeEnd'
                    ) {
                        temp.value = `${Math.floor(new Date(adv.value).getTime() / 1000)}`;
                        // console.log(`after change ${adv.name} and ${temp.value}`);
                    }
                    return temp;
                }),
            );
            // body.fields = advancedSearch;
        }
        // Getting fav to check if document is marked as favorite
        let favoriteDocIds: number[] = [];
        const favoritesRes = await getFavoriteDocIds();
        if (favoritesRes?.apiStatus === 'SUCCESS') {
            favoriteDocIds = favoritesRes.documentIds;
        }
        const response = await postRequest(`search`, body);
        if (response?.apiStatus === 'SUCCESS') {
            const searchDocuments: ISearch[] = [];
            const facets: IFilterConfig[] = [];
            const { facets: facetsList, source: documents, hits: totalCount } = response.response;
            const facetKeys = Object.keys(facetsList);
            const docTypes = await promiseDocTypeOptions('');
            if (facetKeys.length > 0) {
                for (const key of facetKeys) {
                    const options: IOptionValue[] = [];
                    const { buckets } = facetsList[key];
                    if (buckets.length > 0) {
                        for (const bucket of buckets) {
                            if (key === 'documentTypeName') {
                                options.push({
                                    label: `${bucket.key} (${bucket.doc_count})`,
                                    value: docTypes.get(bucket.key) as string,
                                });
                            } else {
                                options.push({
                                    label: `${bucket.key} (${bucket.doc_count})`,
                                    value: bucket.key,
                                });
                            }
                        }
                    }
                    const facetConfig = facetConfigFun(key);
                    facets.push({
                        displayName: facetConfig.displayName,
                        componentType: 'SELECT_FROM_OPTION',
                        name: facetConfig.name,
                        options,
                        placeholder: facetConfig.placeholder,
                    });
                }
            }

            // Add Date Range Component here
            facets.push({
                displayName: 'Date Range',
                componentType: 'DATE_RANGE',
                name: 'Date Range',
                placeholder: 'Select date range',
            });
            facets.push({
                displayName: 'Custom Date Range',
                componentType: 'DOUBLE_DATE',
                name: 'Custom Date Range',
            });
            facets.push({
                displayName: 'Effective Date Range',
                componentType: 'DOUBLE_DATE',
                name: 'Effective Date Range',
            });
            for (const document of documents) {
                const highlighted: IHighlighted[] = [];
                if (document.highlight) {
                    const highlightKeys = Object.keys(document.highlight);
                    for (const key of highlightKeys) {
                        const temp: IHighlighted = {
                            text: document.highlight[key][0] ? document.highlight[key][0] : '',
                            foundIn: '',
                        };
                        if (key.includes('data.')) {
                            temp.foundIn = key.replace('data.', 'field ');
                        } else if (key.includes('attachment.')) {
                            temp.id = document.attachment?.id ? document.attachment?.id : 0;
                            temp.foundIn = document.attachment?.filename
                                ? document.attachment?.filename
                                : '';
                        } else {
                            temp.foundIn = key;
                        }
                        highlighted.push(temp);
                    }
                }
                searchDocuments.push({
                    authorId: document.ownerId,
                    authorName: document.owner ? document.owner.name : '',
                    createdDate: document.approvedOn
                        ? convertEpochToDate(document.approvedOn)
                        : new Date(),
                    docId: document.id,
                    docNumber: document.documentNumber,
                    files: document.activeDocumentVersion
                        ? document.activeDocumentVersion.attachmentCount
                        : 0,
                    status: document.state,
                    title: document.title,
                    description: document.description,
                    version: getDocVersion(document.majorVersion, document.minorVersion),
                    highlighted,
                    isFavorite: !!favoriteDocIds.includes(document.id),
                    isConfidential: document.isConfidential,
                    documentTypeName: document.documentTypeName,
                });
            }

            return { apiStatus: response.apiStatus, searchDocuments, facets, totalCount };
        }
        return undefined;
    } catch (err) {
        // console.log(err);
        return undefined;
    }
};

export {
    getMyApprovals,
    getMyDocuments,
    getAllDraftDCOs,
    getAllFileTypeDocs,
    getLatestDocuments,
    getMyReviews,
    getMyFavorites,
    getGroupApprovals,
    addToFavorites,
    removeFromFavorites,
    getSearchDocuments,
    getFavoriteDocIds,
    fecthDirectfromDb, getAllCount,
};
