import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import Button from '../../components/button/Button';
import { createSeedStockAgreement } from '../../API/document';
import '../nda/ndaForm.scss';
import './SeedStockAgreement.scss';
import { IUserOptionValue } from '../../interfaces';
import CONSTANTS from '../../constants';
import { userSearch } from '../../API/users';

// eslint-disable-next-line max-lines-per-function
const SeedStockAgreement: React.FC = () => {
    const { user } = useAuthDataContext();
    const [docId, setDocId] = useState(0);
    const [documentData, setDocumentData] = useState({
        documentTypeId: 89,
        effectiveFrom: new Date(),
        data: {
            contractType: ['SeedStock'],
            partyName: '',
            enphaseEntity: ['Enphase Energy, Inc.'],
            businessAddress: '',
            territory: '',
            market: '',
            language: '',
        },
        externalApprovers: [],
        approver: [],
    });
    const [selectedUser, setSelectedUser] = useState<IUserOptionValue[]>();
    const [externalSigner, setExternalSigner] = useState({ name: '', email: '', state: 'NEW' });
    const [isLoading, setLoading] = useState(false);
    const [isApiLoading, setIsApiLoading] = useState(false);

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue);
                if (users?.apiStatus === 'SUCCESS') {
                    const userList = users.users;
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };
    const handleMarketChange = (value: string) => {
        setDocumentData({
            ...documentData,
            data: {
                ...documentData.data,
                market: value,
            },
        });
    };
    const handleLanguageChange = (value: string) => {
        setDocumentData({
            ...documentData,
            data: {
                ...documentData.data,
                language: value,
            },
        });
    };
    const submitHandler = async () => {
        const res = await createSeedStockAgreement(
            selectedUser,
            externalSigner,
            documentData
        );
        if (res?.apiStatus === 'SUCCESS'){
            setDocId(res.docId);
            toast.success('Seed Stock Agreement created successfully');
        } else {
            setDocId(0);
            toast.error('Something went wrong');
        }
        setIsApiLoading(false);
    };

    return (
        <div className="seed-stock-form">
            {!docId  ? (
                <form
                    onSubmit={(event) => {
                        if (event.currentTarget.checkValidity())
                        {
                            setIsApiLoading(true);
                            event.preventDefault();
                            submitHandler();
                        }
                    }}
                >
                    <div className="form-title">
                        <h1 className="header1">Enphase Seed Stock Agreement Request</h1>
                        <p className='mandatory-indication'>
                            <span className='mandatory-icon'>*</span>indicates mandatory field
                        </p>
                    </div>

                    <h4 className="header4">
                        Hi {user.name}, please complete the form below and hit Submit. Seed Stock Agreement
                        will be sent to you via email.
                    </h4>

                    <div className="form-group">
                        <label className="mandatory-field">
                            1. What is the desired Effective Date of the Agreement? 
                        </label>
                        <ReactDatePicker
                            className="form-field"
                            selected={documentData.effectiveFrom}
                            disabled={false}
                            onChange={(date: Date) => {
                                setDocumentData({ ...documentData, effectiveFrom: date });
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            2. What is the business&apos; legal name? (Example:  ABC, Inc.) 
                        </label>
                        <input
                            className="form-field"
                            value={documentData.data.partyName}
                            onChange={(e) => {
                                setDocumentData({
                                    ...documentData,
                                    data: {
                                        ...documentData.data,
                                        partyName: e.target.value,
                                    },
                                });
                            }}
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            3. What is the primary business address? (Do not use P.O. boxes) 
                        </label>
                        <input
                            className="form-field"
                            value={documentData.data.businessAddress}
                            onChange={(e) => {
                                setDocumentData({
                                    ...documentData,
                                    data: {
                                        ...documentData.data,
                                        businessAddress: e.target.value,
                                    },
                                });
                            }}
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            4. What is the desired territory? 
                        </label>
                        <input
                            className="form-field"
                            value={documentData.data.territory}
                            onChange={(e) =>
                                setDocumentData({
                                    ...documentData,
                                    data: {
                                        ...documentData.data,
                                        territory: e.target.value,
                                    },
                                })
                            }
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            5. What is the name of authorized Signer for the Other Party 
                        </label>
                        <input
                            className="form-field"
                            value={externalSigner.name}
                            onChange={(e) =>
                                setExternalSigner({ ...externalSigner, name: e.target.value })
                            }
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            6. What is the email address of authorized Signer for the Other Party 
                        </label>
                        <input
                            className="form-field"
                            type="email"
                            value={externalSigner.email}
                            onChange={(e) =>
                                setExternalSigner({ ...externalSigner, email: e.target.value })
                            }
                            autoComplete="off"
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            7. Who will sign for Enphase (i.e. the VP of your business unit)? 
                        </label>
                        <AsyncSelect
                            name="user-search"
                            id="user-search"
                            isLoading={isLoading}
                            isMulti
                            placeholder="Search user..."
                            className={`react-select user-search ${''}`}
                            classNamePrefix="select"
                            loadOptions={promiseOptions}
                            getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                            onChange={(option) => {
                                setSelectedUser(option as IUserOptionValue[]);
                            }}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            8. Select the market 
                        </label>
                        <div className="market-section">
                            <div>
                                <input
                                    type="radio" name="market" id="usa" value="USA"
                                    checked={documentData.data.market === 'USA'}
                                    onChange={() => handleMarketChange('USA')}
                                    required
                                />
                                <label htmlFor="usa">USA</label>
                            </div>
                            <div>
                                <input
                                    type="radio" name="market" id="europe" value="Europe"
                                    checked={documentData.data.market === 'Europe'}
                                    onChange={() => handleMarketChange('Europe')}
                                />
                                <label htmlFor="europe">Europe</label>
                            </div>
                            <div>
                                <input
                                    type="radio" name="market" id="emerging" value="Emerging"
                                    checked={documentData.data.market === 'Emerging'}
                                    onChange={() => handleMarketChange('Emerging')}
                                />
                                <label htmlFor="emerging">Emerging Market</label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="mandatory-field">
                            9. Select the desired language for the agreement 
                        </label>
                        <div className="language-section">
                            <div>
                                <input
                                    type="radio"
                                    name="language"
                                    id="english"
                                    value="English"
                                    checked={documentData.data.language === 'English'}
                                    onChange={() => handleLanguageChange('English')}
                                    required
                                />
                                <label htmlFor="english">English</label>
                            </div>
                            {documentData.data.market === 'Europe' && (
                                <>
                                    <div>
                                        <input
                                            type="radio" name="language" id="dutch" value="Dutch"
                                            checked={documentData.data.language === 'Dutch'}
                                            onChange={() => handleLanguageChange('Dutch')}
                                        />
                                        <label htmlFor="dutch">Dutch</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio" name="language" id="french" value="French"
                                            checked={documentData.data.language === 'French'}
                                            onChange={() => handleLanguageChange('French')}
                                        />
                                        <label htmlFor="french">French</label>
                                    </div>
                                    <div>
                                        <input
                                            type="radio" name="language" id="german" value="German"
                                            checked={documentData.data.language === 'German'}
                                            onChange={() => handleLanguageChange('German')}
                                        />
                                        <label htmlFor="german">German</label>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className='form-footer'>
                    <Button
                        type="submit"
                        isLoading={isApiLoading}
                        disabled={false}
                        className=""
                        onClick={() => {}}
                    >
                        Submit
                    </Button>
                    <p className='feedback-message'>Thank you for using our Seed Stock Agreement Request form.  Please send any feedback you have to:<a href="mailto:alentini@enphaseenergy.com">alentini@enphaseenergy.com</a> </p>
                    </div>
                </form>
            ) : (
                <div>
                    Check your email - you will receive the requested Seed Stock Agreement in your inbox shortly.
                    To review this or previously submitted Seed Stock Agreement - click here ,{' '}
                    <a href={`${process.env.REACT_APP_HOST}/app/dashboard?type=MY_DOCUMENTS`}>
                        Link
                    </a>
                    .
                </div>
            )}
        </div>
    )
}

export default SeedStockAgreement;