/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { QuestionCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';

import { LogoSvg, DocumentTypesIconSvg, PlusIconSvg, ContractsIcon } from '../../assets/images';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import SearchBar from '../../components/search-bar/SearchBar';
import UserHeader from '../../components/user-header/UserHeader';
import './Header.scss';
import DocCreateModal from '../doc-create-modal/DocCreateModal';
import CONSTANTS from '../../constants';
import { useSearch } from '../../contexts/search-provider';
import STATIC_CONTENT from '../../constants/StaticContent';
import { IDocTypePopUp } from '../../interfaces/document-type';
import InitialFeaturesTour from '../../components/introJs/InitialFeaturesTour';
import { getDocTypes } from '../../API/document-type';
import { createDocument } from '../../API/document';

const { HEADER } = STATIC_CONTENT;

const Logo: React.FC = () => {
    const history = useHistory();
    return (
        <div
            className="logo"
            onClick={() => history.push({ pathname: `/${CONSTANTS.RELATIVE_PATHS.dashboard.Url}` })}
            role="presentation"
        >
            <LogoSvg />
            <span />
            <h1>{HEADER.LABEL.DOC_MANAGEMENT_SYSTEM}</h1>
        </div>
    );
};

const Header: React.FC = () => {
    const { user } = useAuthDataContext();
    const { showSearch, setShowSearch } = useSearch();
    const [showDocCreateModal, setShowDocCreateModal] = useState(false);
    const [showCreateDropdown, setShowCreateDropdown] = useState(false);
    const [showDashboardDropdown, setShowDashboardDropdown] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [selectedDocType, setSelectedDocType] = useState<IDocTypePopUp>({
        id: 0,
        name: '',
        description: '',
        updatedAt: new Date(),
        code: '',
        fields: [],
        isAllowedForDCO: false,
        isDCO: false
    });
    const [docTypesList, setDocTypesList] = useState<IDocTypePopUp[]>([]);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [initialStep, setInitialStep] = useState(0);
    const [showTourButton, setshowTourButton] = useState(false);
    const [createChangeBtnLoading, setCreateChangeBtnLoading] = useState(false);

    useEffect(() => {
        if (location.pathname.includes(CONSTANTS.RELATIVE_PATHS.dashboard.Url)) {
            setshowTourButton(true);
        } else {
            setshowTourButton(false);
        }
    }, [location]);
    if (!user.id) {
        return <Redirect to="/signin" />;
    }

    const handleDocumentType = async () => {
        history.push(`/adm/dashboard`);
    };

    const handleContracts = async () => {
        history.push(`/clm/dashboard`);
    };

    const handleCreateClick = (e: any) => {
        e.stopPropagation();
        setShowCreateDropdown((prev) => !prev);
    }
    const handleDashboardClick = (e: any) => {
        e.stopPropagation();
        setShowDashboardDropdown((prev) => !prev);
    }

    const handleCreateChange = async (e: any) => {
        e.stopPropagation();
        setCreateChangeBtnLoading(true);

        const queryParams = {
            inputStr: 'DCO',
            sortBy: 'asc',
        };
        let dcoDocTypeId = -1;
        const res = await getDocTypes(queryParams);
        if (res?.apiStatus === 'SUCCESS') {
            dcoDocTypeId = res?.docTypeCard[0]?.id;
        } else {
            toast.error('Something went wrong while fetching DCO');
        }

        const createDocRes = await createDocument(dcoDocTypeId, {});
        if (createDocRes?.apiStatus === 'SUCCESS') {
            history.push(`/create-document?doc_id=${createDocRes?.docId}`);
        } else if (createDocRes?.apiStatus === 'FAILURE') {
            toast.error(createDocRes.errorMessage);
        }
        setCreateChangeBtnLoading(false);
        setShowCreateDropdown(false);
    }

    const handleReports = () => {
        history.push(`/reportList`);
    }

    return (
        <header className="header">
            <div className="header__top-bar">
                <Logo />
                {user.id && user.id !== -1 && <SearchBar setShowSearch={setShowSearch} />}
                <div className="header__content">
                    {user.id && user.id !== -1 && (
                        <>
                            {stepsEnabled && (
                                <InitialFeaturesTour
                                    stepsEnabled={stepsEnabled}
                                    setStepsEnabled={setStepsEnabled}
                                    initialStep={initialStep}
                                />
                            )}
                            {!showSearch && (
                                <div className="search-buttons">
                                    <button
                                        type="button"
                                        className={`btn primary-btn create-plus-btn ${showCreateDropdown ? 'show' : ''}`}
                                        onClick={handleCreateClick}
                                        onMouseEnter={() => setShowCreateDropdown(true)}
                                        onMouseLeave={() => setShowCreateDropdown(false)}
                                    >
                                        {HEADER.BUTTON.CREATE} <PlusIconSvg />
                                    </button>
                                    {/* {(user.isAdmin || user.isDcoAdmin) && (
                                        <button
                                            type="button"
                                            className="btn-doctypes"
                                            onClick={handleDocumentType}
                                            title="Admin Dashboard"
                                        >
                                            <DocumentTypesIconSvg />
                                        </button>
                                    )}
                                    {(user.isAdmin || user.isClmAdmin) && (
                                        <button
                                            type="button"
                                            className="btn-doctypes"
                                            onClick={handleContracts}
                                            title="Contracts Dashboard"
                                        >
                                            <ContractsIcon />
                                        </button>
                                    )} */}

                                    {/* {showTourButton && (
                                        <button
                                            type="button"
                                            className="btn-retake-icon"
                                            onClick={() => setStepsEnabled(true)}
                                        >
                                            Retake Tour
                                        </button>
                                    )}
                                    <div
                                        className="search-icon"
                                        onClick={() => setShowSearch(true)}
                                        role="presentation"
                                    >
                                        <SearchIconSvg />
                                    </div> */}
                                    {true && (
                                        <div
                                            className={`search-buttons__dropdown ${showCreateDropdown ? 'show' : ''}`}
                                            onMouseEnter={() => setShowCreateDropdown(true)}
                                            onMouseLeave={() => setShowCreateDropdown(false)}
                                        >
                                            <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={() => setShowDocCreateModal(true)}
                                                title="Create Document"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.CREATE_DOC}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={handleCreateChange}
                                                title="Create Change"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.CREATE_CHANGE}
                                                {createChangeBtnLoading && (
                                                    <Loader
                                                        type="Oval"
                                                        color="#F37321"
                                                        height={14}
                                                        width={14}
                                                    />
                                                )}
                                            </button>
                                            {/* <button
                                                type="button"
                                                className="btn-icon"
                                                onClick={handleReports}
                                                title="Reports"
                                                disabled={createChangeBtnLoading}
                                            >
                                                {STATIC_CONTENT.HEADER.BUTTON.REPORTS}
                                            </button> */}
                                        </div>
                                    )}

                                </div>
                            )}
                            {(user.isAdmin || user.isClmAdmin || user.isDcoAdmin) && <div className="dashboard-buttons">
                                <button
                                    type="button"
                                    className={`btn primary-btn dashboard ${showDashboardDropdown ? 'show' : ''}`}
                                    onClick={handleDashboardClick}
                                    onMouseEnter={() => setShowDashboardDropdown(true)}
                                    onMouseLeave={() => setShowDashboardDropdown(false)}
                                >
                                    <DocumentTypesIconSvg />
                                </button>
                                {true && (
                                    <div
                                        className={`dashboard-buttons__dropdown ${showDashboardDropdown ? 'show' : ''}`}
                                        onMouseEnter={() => setShowDashboardDropdown(true)}
                                        onMouseLeave={() => setShowDashboardDropdown(false)}
                                    >
                                        {(user.isAdmin || user.isDcoAdmin) && <button
                                            type="button"
                                            className="btn-icon"
                                            onClick={handleDocumentType}
                                            title="Admin Dashboard"
                                        >
                                            Admin Dashboard
                                        </button>}
                                        {(user.isAdmin || user.isClmAdmin) && <button
                                            type="button"
                                            className="btn-icon"
                                            onClick={handleContracts}
                                            title="Contracts Dashboard"
                                        >
                                            CLM Dashboard
                                        </button>}
                                    </div>
                                )}
                            </div>}

                        </>
                    )}
                    <UserHeader />
                    <div
                        role="presentation"
                        className="user-guide"
                        onClick={() => history.push(`/documentation`)}
                        onKeyPress={() => history.push('/documentation')}
                    >
                        <QuestionCircle style={{ color: '#000000', fontSize: '24px' }} />
                    </div>
                </div>
            </div>
            {user.id && user.id !== -1 && (
                <div className="header__search-bar">
                    <SearchBar setShowSearch={setShowSearch} />
                </div>
            )}
            {/* <div className="header__mobile-doc-create-btn">
                <button
                    type="button"
                    className="btn-icon"
                    onClick={() => setShowDocCreateModal(true)}
                >
                    <EditSvg />
                </button>
            </div> */}
            {showDocCreateModal && (
                <DocCreateModal
                    isModalOpen={showDocCreateModal}
                    onModalClose={setShowDocCreateModal}
                    selectedDocType={selectedDocType}
                    setSelectedDocType={setSelectedDocType}
                    docTypesList={docTypesList}
                    setDocTypesList={setDocTypesList}
                />
            )}
        </header>
    );
};

export default Header;
