/* eslint-disable no-debugger */
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    checkInDocument,
    documentAction,
    getAllDocVersionIds,
    getDocumentsDetails,
    updateDCOfields,
} from '../../API/document';
import { SuccessfulIcon } from '../../assets/images';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { IButtonStatus } from '../../interfaces';
import { IDocumentActionTypes } from '../../interfaces/document';
import CheckoutConfirmationModel from '../../widgets/document-info/CheckoutConfirmationModel';
import ActivitySection from '../activity-section/ActivitySection';
import Attachments from '../attachments/edit/EditAttachments';
import Button from '../button/Button';
import ChecklistComponent from '../checklist/checklistComponent';
import ConfirmationModal, { IAcceptedType } from '../confirmation-modal/ConfirmationModal';
import DocumentHistory from '../document-history/DocumentHistory';
import ImportantFields from '../important-fields/ImportantFields';
import LinkedDocuments from '../input-fields/linked-documents/LinkedDocuments';
import RefDocsTreeNodes from '../reference-documents-tree/RefDocsTreeNodes';
import WorkFlowPanel from '../work-flow-panel/WorkFlowPanel';
import './NewDocMain.scss';
import VersionConfirmationModal from './VersionConfirmationModal';

interface Props {
    isDisabled: boolean;
}

// eslint-disable-next-line max-lines-per-function
const SinglePageDoc: React.FC<Props> = ({ isDisabled }: Props) => {
    const {
        saveFieldsData,
        isFieldsDataSaved,
        fields,
        fieldsData,
        onDocumentSubmit,
        onDocumentCheckIn,
        documentId,
        submitDocStatus,
        validateReferenceDocuments,
        documentDispatch,
        error: {
            STEP1: { errorFields },
        },
        approvers,
        accessibleByCurrentUser,
        taskId,
        attachmentConfig,
        docTypeInfo,
        docVersionCheckedInFlag,
        setDocVersionCheckedInFlag,
        activeDocVersionId,
        fetchDocumentVersion,
        latestrelationArr,
        versionNumberInfo,
        docInfo,
        docVersions,
        showVersionModel,
        setShowVersionModel,
        relationArr,
        checkInVersionOwner,
        selectedDocVersion,
    } = useDocumentContext();
    const { search } = useLocation();
    const queryObj = qs.parse(search, { ignoreQueryPrefix: true });
    const [modalOpen, setModalOpen] = useState(false);
    const { DOCUMENT_INFO, CREATE_DOCUMENTS } = STATIC_CONTENT;
    const [newDocNumber, setNewDocNumber] = useState('');
    const [checkInLoading, setCheckInLoading] = useState(false);
    const [showConfirmationModal, setConfirmationModal] = useState(false);
    const [showCancelModal, setCancelModal] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [SaveLoading, setSaveLoading] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [confirmClicked, setConfirmClicked] = useState(false);
    const history = useHistory();

    const closeVersionModal = async () => {
        setShowVersionModel(false);
        setCheckInLoading(false);
    };

    useEffect(() => {
        setConfirmationModal(true);
        if (approvers.length > 0) {
            approvers.forEach((approver) => {
                if (approver.approvers.length > 0) {
                    setConfirmationModal(false);
                }
            });
        }
    }, [approvers]);
    const submitHandler = async () => {
        if (accessibleByCurrentUser && taskId !== null && taskId !== '') {
            // if dcon edits the doc
            setSaveLoading(true);
            const referenceValidation = await validateReferenceDocuments();
            setSaveLoading(false);
            if (referenceValidation.isValid) {
                setSaveLoading(true);
                // const docs = fieldsData.find((x) => x?.name === 'documents');
                // eslint-disable-next-line array-callback-return
                // docs?.value?.map(async (doc: any) => {
                //     if (!doc.documentLock) await toggleDocumentLock(true, doc.id);
                // });
                await saveFieldsData(true);
                setSaveLoading(false);
                history.push(`/task-details?task_id=${taskId}&prev_page_type=MY_GROUP_APPROVALS`);
            }
        } else {
            const status = await onDocumentSubmit();
            if (status.status === 'SUCCESS') {
                const { pathname } = window.location;
                if (pathname.includes('create-document-in-dco')) {
                    if (queryObj.doc_id && queryObj.dcoDocumentId && queryObj.doc_version_id) {
                        history.push(
                            `/create-document?doc_id=${queryObj.dcoDocumentId}&newDocversionId=${queryObj.doc_version_id}`,
                        );
                    }
                } else {
                    setNewDocNumber(status.docNumber);
                    setModalOpen(true);
                }
            }
        }
    };
    const continueCheckin = async () => {
        const response = await checkInDocument(true, activeDocVersionId);
        if (response?.apiStatus === 'SUCCESS') {
            toast.success(CREATE_DOCUMENTS.TOAST.SUCCESS_MSG.CHECK_IN);
            setDocVersionCheckedInFlag(true);
        } else {
            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.CHECK_IN);
        }
        setCheckInLoading(false);
    };
    const closeModal = async () => {
        setCheckInLoading(true);
        await saveFieldsData(false);
        const { pathname } = window.location;
        if (pathname.includes('create-document-in-dco')) {
            if (queryObj.doc_id && queryObj.dcoDocumentId && queryObj.doc_version_id) {
                history.push(
                    `/create-document?doc_id=${queryObj.dcoDocumentId}&newDocversionId=${queryObj.doc_version_id}`,
                );
            }
        }
        const res = await onDocumentCheckIn(true, activeDocVersionId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(CREATE_DOCUMENTS.TOAST.SUCCESS_MSG.CHECK_IN);
            setDocVersionCheckedInFlag(true);
        } else if (!res) {
            return;
        } else {
            toast.error(CREATE_DOCUMENTS.TOAST.ERROR_MSG.CHECK_IN);
        }
        setCheckInLoading(false);
    };

    useEffect(() => {
        if (confirmClicked) {
            submitHandler();
        }
        setConfirmClicked(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmClicked]);

    Modal.setAppElement('#root');
    const [confirmationModalData, setConfirmationModalData] = useState({
        type: 'EMPTY_APPROVER',
        onConfirm: () => {
            setConfirmClicked(true);
        },
    });

    const cancelButtonHandler = async () => {
        setCancelLoading(true);
        await fetchDocumentVersion();
        setCancelLoading(false);
    };
    const [cancelModalData, setCancelModalData] = useState({
        type: 'CANCEL',
        onConfirm: () => {
            cancelButtonHandler();
        },
    });

    const [activeTab, setActiveTab] = useState('Doc_Details');
    const [activeTabInHistory, setActiveTabInHistory] = useState('General');
    const [fieldData] = fieldsData.filter((a) => a.name === 'LinkedDocuments');
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [showCheckoutConfirmationModal, setCheckoutConfirmationModal] = useState(false);
    const { user } = useAuthDataContext();
    // eslint-disable-next-line max-lines-per-function
    const onClick = async (type: IDocumentActionTypes) => {
        setButtonStatus('LOADING');
        const res = await documentAction(documentId, type, docTypeInfo);
        if (res?.apiStatus === 'SUCCESS') {
            if (type === 'REVERT') {
                const draftRelationArr = relationArr.filter((obj) => obj?.state === 'DRAFT');
                if (draftRelationArr.length > 0) {
                    const versionIdList = await getAllDocVersionIds([documentId]);
                    const docVersionIds = versionIdList ? versionIdList?.documentVersionIds : [];
                    const NewVersionData = await getDocumentsDetails(docVersionIds);
                    const DCOList = draftRelationArr.map((obj) => obj.activeVersionId);
                    const res1 = await updateDCOfields(DCOList, NewVersionData?.data, documentId);
                    if (res1?.apiStatus === 'SUCCESS')
                        toast.success(
                            'Reference has been updated to the old version in all the draft DCOs this document is part of',
                        );
                }
                toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.REVERTED);
                history.push({
                    pathname: `${CONSTANTS.RELATIVE_PATHS.documentDetails.Url}`,
                    search: `?doc_id=${documentId}`,
                });
            }
            if (type === 'DISCARD') {
                if (queryObj.dcoDocumentId) {
                    window.history.back();
                } else {
                    history.push(`${CONSTANTS.RELATIVE_PATHS.dashboard.Url}`);
                }
                if (docTypeInfo.isDCO) {
                    toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.DELETED);
                } else {
                    toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.DISCARDED);
                }
            }
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
        setButtonStatus('DEFAULT');
    };
    const [deleteConfirmationModalData, setDeleteConfirmationModalData] = useState({
        type: 'DISCARD',
        onConfirm: () => { },
    });

    const handleCheckout = async () => {
        setButtonStatus('LOADING');
        const res = await onDocumentCheckIn(false, activeDocVersionId);
        if (res?.apiStatus === 'SUCCESS') {
            toast.success(DOCUMENT_INFO.TOAST.SUCCESS_MSG.CHECK_OUT);
            history.push(`${CONSTANTS.RELATIVE_PATHS.createDocument.Url}?doc_id=${documentId}`);
            setDocVersionCheckedInFlag(false);
        } else {
            toast.error(DOCUMENT_INFO.TOAST.ERROR_MSG.CHECK_OUT);
        }
        setButtonStatus('DEFAULT');
    };

    const checkoutState = async () => {
        if (!docVersionCheckedInFlag && user.id !== checkInVersionOwner.id)
            setCheckoutConfirmationModal(true);
        else handleCheckout();
    };

    return (
        <>
            {docTypeInfo.allowDCO || docTypeInfo.storeAndShowActivity ? (
                <div className="document-details-tabs">
                    <div className="tab-in-singlepage tab">
                        <button
                            id="Doc_Details"
                            type="button"
                            className={`${activeTab === 'Doc_Details' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Doc_Details')}
                        >
                            <span className="name">
                                {docTypeInfo.isDCO ? 'Affected Documents' : 'Details'}
                            </span>
                        </button>
                        <button
                            type="button"
                            id="Doc_History"
                            className={`${activeTab === 'Doc_History' ? 'active' : ''}`}
                            onClick={() => setActiveTab('Doc_History')}
                        >
                            <span className="name">History</span>
                        </button>
                        {docTypeInfo.allowDCO && (
                            <button
                                type="button"
                                id="Ref_Docs_Tree"
                                className={`${activeTab === 'Ref_Docs_Tree' ? 'active' : ''}`}
                                onClick={() => setActiveTab('Ref_Docs_Tree')}
                            >
                                <span className="name">Relationships</span>
                            </button>
                        )}
                    </div>
                    {activeTab === 'Doc_Details' && (
                        <div className="new-doc-main activityDocMain">
                            {docTypeInfo.isDCO ? (
                                <h2>Affected Documents</h2>
                            ) : (
                                <h2>Document Details</h2>
                            )}
                            <ImportantFields
                                isDisabled={
                                    isDisabled ||
                                    latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                                    docVersionCheckedInFlag
                                }
                                fields={
                                    docTypeInfo.allowDCO
                                        ? fields.filter(
                                            (field) => field.uiControl.label !== 'Reference',
                                        )
                                        : fields
                                }
                                fieldsData={fieldsData}
                                documentDispatch={documentDispatch}
                                errorFields={errorFields}
                            />
                            {!attachmentConfig[0].allowDragDrop && (
                                <>
                                    <br />
                                    <h2>Attachments</h2>
                                </>
                            )}
                            <Attachments />
                            {docTypeInfo.allowDCO && fieldData && (
                                <>
                                    <br />
                                    <LinkedDocuments
                                        documentDispatch={documentDispatch || null}
                                        field={
                                            fields.filter((f) => f.name === 'LinkedDocuments')[0]
                                        }
                                        isDisabled={false}
                                        value={
                                            fieldData?.value ? (fieldData.value as string[]) : []
                                        }
                                        error={errorFields.includes('LinkedDocuments')}
                                        isEditable={documentDispatch !== null}
                                        isChild={false}
                                    />
                                </>
                            )}
                            <ChecklistComponent isDisabled={false} />
                            {!docTypeInfo.allowDCO && (
                                <>
                                    <br />
                                    <h2>Approvals</h2>
                                    <WorkFlowPanel />
                                </>
                            )}
                            {docInfo.docState === 'DRAFT' &&
                                docTypeInfo.allowDCO &&
                                (docVersionCheckedInFlag || docInfo.versionOwner.id !== user.id) &&
                                (latestrelationArr.length > 0
                                    ? latestrelationArr.some((obj) => obj.state !== 'UNDER_REVIEW')
                                    : true) && (
                                    <div className="checkout-button">
                                        <Button
                                            type="button"
                                            onClick={checkoutState}
                                            disabled={
                                                buttonStatus === 'LOADING' ||
                                                docInfo.title === CONSTANTS.LOADING.TEXT
                                            }
                                            className="primary-btn"
                                            isLoading={buttonStatus === 'LOADING'}
                                        >
                                            {CREATE_DOCUMENTS.BUTTON.CHECK_OUT}
                                        </Button>
                                    </div>
                                )}
                            <div className="buttons">
                                {!(
                                    accessibleByCurrentUser && docInfo.docState === 'UNDER_REVIEW'
                                ) &&
                                    (docInfo.approvedVersionId &&
                                        (selectedDocVersion.value as number)) !==
                                    docInfo.approvedVersionId &&
                                    docInfo.docState !== 'REJECTED' &&
                                    !latestrelationArr.some(
                                        (obj) => obj.state === 'UNDER_REVIEW',
                                    ) &&
                                    !docVersionCheckedInFlag && (
                                        <Button
                                            type="button"
                                            disabled={
                                                buttonStatus === 'LOADING' ||
                                                docInfo.title === CONSTANTS.LOADING.TEXT
                                            }
                                            className=""
                                            isLoading={
                                                buttonStatus === 'LOADING' &&
                                                confirmationModalData.type === 'REVERT'
                                            }
                                            title="Revert to previous version"
                                            onClick={() => {
                                                setDeleteConfirmationModal(true);
                                                setDeleteConfirmationModalData({
                                                    onConfirm: () => onClick('REVERT'),
                                                    type: 'REVERT',
                                                });
                                            }}
                                        >
                                            {DOCUMENT_INFO.BUTTON.REVERT}
                                        </Button>
                                    )}
                                {!docVersionCheckedInFlag &&
                                    (docTypeInfo.allowDCO || docTypeInfo.isDCO) &&
                                    !latestrelationArr.some(
                                        (obj) => obj.state === 'UNDER_REVIEW',
                                    ) && (
                                        <>
                                            <Button
                                                type="button"
                                                disabled={
                                                    buttonStatus === 'LOADING' ||
                                                    docInfo.title === CONSTANTS.LOADING.TEXT
                                                }
                                                className=""
                                                isLoading={
                                                    buttonStatus === 'LOADING' &&
                                                    deleteConfirmationModalData.type === 'REVERT'
                                                }
                                                onClick={() => {
                                                    setDeleteConfirmationModal(true);
                                                    setDeleteConfirmationModalData({
                                                        onConfirm: () => onClick('DISCARD'),
                                                        type: 'DISCARD',
                                                    });
                                                }}
                                            >
                                                {`${DOCUMENT_INFO.BUTTON.DELETE}`}
                                            </Button>
                                            <Button
                                                type="button"
                                                className="secondary"
                                                onClick={() => setCancelModal(true)}
                                                disabled={false}
                                                isLoading={cancelLoading}
                                                title="Discard edits after last save"
                                            >
                                                {CREATE_DOCUMENTS.BUTTON.CANCEL}
                                            </Button>
                                        </>
                                    )}
                                {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                                    docTypeInfo.allowDCO &&
                                    attachmentConfig.length &&
                                    !docVersionCheckedInFlag && (
                                        <Button
                                            type="button"
                                            className="secondary"
                                            onClick={() => saveFieldsData(true)}
                                            disabled={false}
                                            isLoading={isFieldsDataSaved}
                                        >
                                            {CREATE_DOCUMENTS.BUTTON.SAVE}
                                        </Button>
                                    )}
                                {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                                    docTypeInfo.allowDCO &&
                                    attachmentConfig.length &&
                                    !docVersionCheckedInFlag && (
                                        <>
                                            <Button
                                                type="button"
                                                className="primary-btn"
                                                onClick={closeModal}
                                                disabled={false}
                                                isLoading={isFieldsDataSaved || checkInLoading}
                                            >
                                                {CREATE_DOCUMENTS.BUTTON.CHECK_IN}
                                            </Button>
                                            {showVersionModel && (
                                                <VersionConfirmationModal
                                                    isModalOpen={showVersionModel}
                                                    onModalClose={closeVersionModal}
                                                    onConfirm={continueCheckin}
                                                />
                                            )}
                                        </>
                                    )}
                                {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                                    !docTypeInfo.allowDCO &&
                                    attachmentConfig.length && (
                                        <>
                                            {!(
                                                accessibleByCurrentUser &&
                                                taskId !== null &&
                                                taskId !== ''
                                            ) && (
                                                    <Button
                                                        type="button"
                                                        className="secondary"
                                                        onClick={() => saveFieldsData(true)}
                                                        disabled={false}
                                                        isLoading={isFieldsDataSaved}
                                                    >
                                                        {CREATE_DOCUMENTS.BUTTON.SAVE}
                                                    </Button>
                                                )}
                                            <Button
                                                type="button"
                                                className="primary-btn"
                                                onClick={async () => {
                                                    setSubmitClicked(true);
                                                    if (!showConfirmationModal) submitHandler();
                                                }}
                                                disabled={
                                                    submitDocStatus === 'LOADING' || SaveLoading
                                                }
                                                isLoading={
                                                    submitDocStatus === 'LOADING' || SaveLoading
                                                }
                                                loaderColor="#f37321"
                                            >
                                                {accessibleByCurrentUser &&
                                                    taskId !== null &&
                                                    taskId !== ''
                                                    ? 'Save'
                                                    : CREATE_DOCUMENTS.BUTTON.SUBMIT}
                                            </Button>
                                        </>
                                    )}
                            </div>
                            <Modal
                                isOpen={modalOpen}
                                contentLabel="submit Modal"
                                className="doc_submit__modal"
                            >
                                <div className="modal-body">
                                    <div className="icon">
                                        <SuccessfulIcon />
                                    </div>
                                    {docTypeInfo.isDCO ? (
                                        <h1>DCO Submitted</h1>
                                    ) : (
                                        <h1>Document Submitted</h1>
                                    )}
                                    {newDocNumber &&
                                        (docTypeInfo.isDCO ? (
                                            <h4>
                                                Change Number : <i>{newDocNumber}</i>
                                            </h4>
                                        ) : (
                                            <h4>
                                                Document Number : <i>{newDocNumber}</i>
                                            </h4>
                                        ))}
                                    <p>On an average, it takes about 2 days for approvals</p>
                                    <button
                                        className="primary-btn"
                                        type="button"
                                        onClick={() =>
                                            history.push(`document-details?doc_id=${documentId}`)
                                        }
                                    >
                                        {CREATE_DOCUMENTS.BUTTON.VIEW_SUBMISSION}
                                    </button>
                                </div>
                            </Modal>
                            {submitClicked && showConfirmationModal && (
                                <ConfirmationModal
                                    type={confirmationModalData.type as IAcceptedType}
                                    isModalOpen={showConfirmationModal}
                                    onModalClose={setSubmitClicked}
                                    onConfirm={confirmationModalData.onConfirm}
                                    isDCO={docTypeInfo.isDCO}
                                />
                            )}
                            {showCancelModal && (
                                <ConfirmationModal
                                    type={cancelModalData.type as IAcceptedType}
                                    isModalOpen={showCancelModal}
                                    onModalClose={setCancelModal}
                                    onConfirm={cancelModalData.onConfirm}
                                />
                            )}
                            {deleteConfirmationModal && (
                                <ConfirmationModal
                                    type={deleteConfirmationModalData.type as IAcceptedType}
                                    isModalOpen={deleteConfirmationModal}
                                    onModalClose={setDeleteConfirmationModal}
                                    onConfirm={deleteConfirmationModalData.onConfirm}
                                    isDCO={docTypeInfo.isDCO}
                                />
                            )}
                            {showCheckoutConfirmationModal && (
                                <CheckoutConfirmationModel
                                    isCheckoutModalOpen={showCheckoutConfirmationModal}
                                    onCheckoutModalClose={setCheckoutConfirmationModal}
                                    onCheckoutConfirm={handleCheckout}
                                    checkInVersionOwner={checkInVersionOwner}
                                />
                            )}
                        </div>
                    )}

                    {activeTab === 'Doc_History' && (
                        <div className="history-tab">
                            <div className="doc-history">
                                <div className="doc-history-tab">
                                    <button
                                        type="button"
                                        id="General"
                                        className={`${activeTabInHistory === 'General' ? 'active' : ''
                                            }`}
                                        onClick={() => setActiveTabInHistory('General')}
                                    >
                                        <span className="name">General</span>
                                    </button>
                                    {docTypeInfo.allowDCO && (
                                        <button
                                            type="button"
                                            id="Revisions"
                                            className={`${activeTabInHistory === 'Revisions' ? 'active' : ''
                                                }`}
                                            onClick={() => setActiveTabInHistory('Revisions')}
                                        >
                                            <span className="name">Revisions</span>
                                        </button>
                                    )}
                                </div>
                                {activeTabInHistory === 'General' && (
                                    <ActivitySection
                                        documentId={documentId}
                                        docTypeInfo={docTypeInfo}
                                    />
                                )}
                                {docTypeInfo.allowDCO && activeTabInHistory === 'Revisions' && (
                                    <DocumentHistory documentId={documentId} />
                                )}
                            </div>
                        </div>
                    )}

                    {docTypeInfo.allowDCO && activeTab === 'Ref_Docs_Tree' && (
                        <div className="doc-history">
                            <RefDocsTreeNodes activeDocVersionId={activeDocVersionId} />
                        </div>
                    )}
                </div>
            ) : (
                <div className="new-doc-main">
                    {docTypeInfo.isDCO ? <h2>Affected Documents</h2> : <h2>Document Details</h2>}
                    <ImportantFields
                        isDisabled={
                            isDisabled ||
                            latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') ||
                            docVersionCheckedInFlag
                        }
                        fields={
                            docTypeInfo.allowDCO
                                ? fields.filter((field) => field.uiControl.label !== 'Reference')
                                : fields
                        }
                        fieldsData={fieldsData}
                        documentDispatch={documentDispatch}
                        errorFields={errorFields}
                    />
                    {!attachmentConfig[0].allowDragDrop && (
                        <>
                            <br />
                            <h2>Attachments</h2>
                        </>
                    )}
                    <Attachments />
                    {docTypeInfo.allowDCO && fieldData && (
                        <>
                            <br />
                            <LinkedDocuments
                                documentDispatch={documentDispatch || null}
                                field={fields.filter((f) => f.name === 'LinkedDocuments')[0]}
                                isDisabled={false}
                                value={fieldData?.value ? (fieldData.value as string[]) : []}
                                error={errorFields.includes('LinkedDocuments')}
                                isEditable={documentDispatch !== null}
                                isChild={false}
                            />
                        </>
                    )}
                    <ChecklistComponent isDisabled={false} />
                    {!docTypeInfo.allowDCO && (
                        <>
                            <br />
                            <h2>Approvals</h2>
                            <WorkFlowPanel />
                        </>
                    )}
                    {docInfo.docState === 'DRAFT' &&
                        docTypeInfo.allowDCO &&
                        (docVersionCheckedInFlag || docInfo.versionOwner.id !== user.id) &&
                        (latestrelationArr.length > 0
                            ? latestrelationArr.some((obj) => obj.state !== 'UNDER_REVIEW')
                            : true) && (
                            <div className="checkout-button">
                                <Button
                                    type="button"
                                    onClick={checkoutState}
                                    disabled={
                                        buttonStatus === 'LOADING' ||
                                        docInfo.title === CONSTANTS.LOADING.TEXT
                                    }
                                    className="primary-btn"
                                    isLoading={buttonStatus === 'LOADING'}
                                >
                                    {CREATE_DOCUMENTS.BUTTON.CHECK_OUT}
                                </Button>
                            </div>
                        )}
                    <div className="buttons">
                        {!(accessibleByCurrentUser && docInfo.docState === 'UNDER_REVIEW') &&
                            (docInfo.approvedVersionId && (selectedDocVersion.value as number)) !==
                            docInfo.approvedVersionId &&
                            docInfo.docState !== 'REJECTED' &&
                            !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                            !docVersionCheckedInFlag && (
                                <Button
                                    type="button"
                                    disabled={
                                        buttonStatus === 'LOADING' ||
                                        docInfo.title === CONSTANTS.LOADING.TEXT
                                    }
                                    className=""
                                    isLoading={
                                        buttonStatus === 'LOADING' &&
                                        confirmationModalData.type === 'REVERT'
                                    }
                                    title="Revert to previous version"
                                    onClick={() => {
                                        setDeleteConfirmationModal(true);
                                        setDeleteConfirmationModalData({
                                            onConfirm: () => onClick('REVERT'),
                                            type: 'REVERT',
                                        });
                                    }}
                                >
                                    {DOCUMENT_INFO.BUTTON.REVERT}
                                </Button>
                            )}
                        {!docVersionCheckedInFlag &&
                            (docTypeInfo.allowDCO || docTypeInfo.isDCO) &&
                            !latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') && (
                                <>
                                    <Button
                                        type="button"
                                        disabled={
                                            buttonStatus === 'LOADING' ||
                                            docInfo.title === CONSTANTS.LOADING.TEXT
                                        }
                                        className=""
                                        isLoading={
                                            buttonStatus === 'LOADING' &&
                                            deleteConfirmationModalData.type === 'REVERT'
                                        }
                                        onClick={() => {
                                            setDeleteConfirmationModal(true);
                                            setDeleteConfirmationModalData({
                                                onConfirm: () => onClick('DISCARD'),
                                                type: 'DISCARD',
                                            });
                                        }}
                                    >
                                        {`${DOCUMENT_INFO.BUTTON.DELETE}`}
                                    </Button>
                                    <Button
                                        type="button"
                                        className="secondary"
                                        onClick={() => setCancelModal(true)}
                                        disabled={false}
                                        isLoading={cancelLoading}
                                        title="Discard edits after last save"
                                    >
                                        {CREATE_DOCUMENTS.BUTTON.CANCEL}
                                    </Button>
                                </>
                            )}
                        {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                            docTypeInfo.allowDCO &&
                            attachmentConfig.length &&
                            !docVersionCheckedInFlag && (
                                <Button
                                    type="button"
                                    className="secondary"
                                    onClick={() => saveFieldsData(true)}
                                    disabled={false}
                                    isLoading={isFieldsDataSaved}
                                >
                                    {CREATE_DOCUMENTS.BUTTON.SAVE}
                                </Button>
                            )}
                        {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                            docTypeInfo.allowDCO &&
                            attachmentConfig.length &&
                            !docVersionCheckedInFlag && (
                                <>
                                    <Button
                                        type="button"
                                        className="primary-btn"
                                        onClick={closeModal}
                                        disabled={false}
                                        isLoading={isFieldsDataSaved || checkInLoading}
                                    >
                                        {CREATE_DOCUMENTS.BUTTON.CHECK_IN}
                                    </Button>
                                    {showVersionModel && (
                                        <VersionConfirmationModal
                                            isModalOpen={showVersionModel}
                                            onModalClose={closeVersionModal}
                                            onConfirm={continueCheckin}
                                        />
                                    )}
                                </>
                            )}
                        {!latestrelationArr.some((obj) => obj.state === 'UNDER_REVIEW') &&
                            !docTypeInfo.allowDCO &&
                            attachmentConfig.length && (
                                <>
                                    {!(
                                        accessibleByCurrentUser &&
                                        taskId !== null &&
                                        taskId !== ''
                                    ) && (
                                            <Button
                                                type="button"
                                                className="secondary"
                                                onClick={() => saveFieldsData(true)}
                                                disabled={false}
                                                isLoading={isFieldsDataSaved}
                                            >
                                                {CREATE_DOCUMENTS.BUTTON.SAVE}
                                            </Button>
                                        )}
                                    <Button
                                        type="button"
                                        className="primary-btn"
                                        onClick={async () => {
                                            setSubmitClicked(true);
                                            if (!showConfirmationModal) submitHandler();
                                        }}
                                        disabled={submitDocStatus === 'LOADING' || SaveLoading}
                                        isLoading={submitDocStatus === 'LOADING' || SaveLoading}
                                        loaderColor="#f37321"
                                    >
                                        {accessibleByCurrentUser && taskId !== null && taskId !== ''
                                            ? 'Save'
                                            : CREATE_DOCUMENTS.BUTTON.SUBMIT}
                                    </Button>
                                </>
                            )}
                    </div>
                    <Modal
                        isOpen={modalOpen}
                        contentLabel="submit Modal"
                        className="doc_submit__modal"
                    >
                        <div className="modal-body">
                            <div className="icon">
                                <SuccessfulIcon />
                            </div>
                            {docTypeInfo.isDCO ? (
                                <h1>DCO Submitted</h1>
                            ) : (
                                <h1>Document Submitted</h1>
                            )}
                            {newDocNumber &&
                                (docTypeInfo.isDCO ? (
                                    <h4>
                                        Change Number : <i>{newDocNumber}</i>
                                    </h4>
                                ) : (
                                    <h4>
                                        Document Number : <i>{newDocNumber}</i>
                                    </h4>
                                ))}
                            <p>On an average, it takes about 2 days for approvals</p>
                            <button
                                className="primary-btn"
                                type="button"
                                onClick={() =>
                                    history.push(`document-details?doc_id=${documentId}`)
                                }
                            >
                                {CREATE_DOCUMENTS.BUTTON.VIEW_SUBMISSION}
                            </button>
                        </div>
                    </Modal>
                    {submitClicked && showConfirmationModal && (
                        <ConfirmationModal
                            type={confirmationModalData.type as IAcceptedType}
                            isModalOpen={showConfirmationModal}
                            onModalClose={setSubmitClicked}
                            onConfirm={confirmationModalData.onConfirm}
                            isDCO={docTypeInfo.isDCO}
                        />
                    )}
                    {showCancelModal && (
                        <ConfirmationModal
                            type={cancelModalData.type as IAcceptedType}
                            isModalOpen={showCancelModal}
                            onModalClose={setCancelModal}
                            onConfirm={cancelModalData.onConfirm}
                        />
                    )}
                    {deleteConfirmationModal && (
                        <ConfirmationModal
                            type={deleteConfirmationModalData.type as IAcceptedType}
                            isModalOpen={deleteConfirmationModal}
                            onModalClose={setDeleteConfirmationModal}
                            onConfirm={deleteConfirmationModalData.onConfirm}
                            isDCO={docTypeInfo.isDCO}
                        />
                    )}
                    {showCheckoutConfirmationModal && (
                        <CheckoutConfirmationModel
                            isCheckoutModalOpen={showCheckoutConfirmationModal}
                            onCheckoutModalClose={setCheckoutConfirmationModal}
                            onCheckoutConfirm={handleCheckout}
                            checkInVersionOwner={checkInVersionOwner}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default SinglePageDoc;
