/* eslint-disable max-lines-per-function */
import React from 'react';
import AdminSideNavigation from '../../../components/admin-page/AdminSideNavigation';
import PartyNameTable from '../../../components/partynames/PartyNameTable';
import "../../admin-dashboard/AdminDashboard.scss";

const PartyNames: React.FC = () => (
    <div className="admin-dashboard">
        <AdminSideNavigation />
        <div className="admin-delegations admin-dashboard-main">
            <PartyNameTable />
        </div>
    </div>
);

export default PartyNames;