
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { IFileTypeDoc } from '../../../interfaces/attachment';
import { IFieldsData } from '../../../interfaces/document';
import './ViewLinkedFiles.scss';
import CopyText from '../../CopyText';
import CONSTANTS from '../../../constants';
import { DownloadAttachment } from '../../attachments/view/ViewAttachments';
import { formatDateTime } from '../../../utils/date';
import { BadgeIcon } from '../../../assets/images';

interface Props {
    filesData: IFieldsData
}
/* eslint-disable max-lines-per-function */
const ViewLinkedFiles: React.FC<Props> = ({ filesData }) => (
    <div className="files-list">
        <label htmlFor="">files</label>
        <div className="right-section">
            <div className="attachment-list">
                <div className="attachment-item files-header">
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>File Number</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Attachment</h5>
                    </div>
                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Description</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Uploaded on</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Released on</h5>
                    </div>

                    <div className="attachment-name">
                        <h5 style={{ color: 'black' }}>Actions</h5>
                    </div>
                </div>
                {filesData && filesData.value?.map((currFile: IFileTypeDoc, fIdx: number) => {
                    const uploadDate = new Date(currFile.uploadedOn * 1000);
                    let releaseDate;
                    if (currFile.releasedOn && currFile.releasedOn !== 'N/A') {
                        releaseDate = new Date(currFile.releasedOn * 1000);
                    }
                    return (
                        <div className="attachment-item">
                            <div className="attachment-name badge">
                                <h5 style={{ color: 'black' }}>{currFile.fileNumber}</h5>
                                {currFile.isPrimary && (
                                    <span
                                        className="primary-file"
                                    >Primary</span>
                                )}
                            </div>

                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>{currFile.name}</h5>
                            </div>

                            <div className="doc-description">
                                <h5 style={{ color: 'black' }}>{currFile.description || 'N/A'}</h5>
                            </div>

                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>
                                    {formatDateTime(uploadDate)}
                                </h5>
                            </div>

                            <div className="attachment-name">
                                <h5 style={{ color: 'black' }}>
                                    {releaseDate ? formatDateTime(releaseDate) : 'N/A'}
                                </h5>
                            </div>

                            <div className="file-actions">
                                <DownloadAttachment attachmentId={currFile.attachmentId} fileName={currFile.name} />
                                <div className="copy-smartlink" data-tip='Copy Smartlink' data-for='smartlink-tooltip'>
                                    <CopyText
                                        text=''
                                        link={`${process.env.REACT_APP_HOST}${CONSTANTS.RELATIVE_PATHS.fileDetails.WPUrl}?doc_id=${currFile.id}`}
                                        differentIcon
                                    />
                                </div>
                                <ReactTooltip className='tooltip-docinfo' id="smartlink-tooltip" effect="solid" place="top" />
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </div >
);

export default ViewLinkedFiles;
