import { addDays, format, isToday, isYesterday } from 'date-fns';

const formatDate = (date: Date): string => {
    if (isToday(date)) return `Today`;
    if (isYesterday(date)) return `Yesterday`;

    return format(date, 'MM-dd-yyyy');
};

const formatDateTime = (date: Date): string => `${format(date, 'MM-dd-yyyy')} ${format(date, 'hh:mm:ss a')}`;

const futureFormattedDate = (days: number): string => {
    const currDate = new Date();
    return format(addDays(currDate, days), 'dd-MM-yyyy');
}

const formatTime = (date: Date): string => format(date, 'hh:mm a');

const formatDateForEvent = (date: Date): string => {
    if (isToday(date)) return `Today at ${formatTime(date)}`;
    if (isYesterday(date)) return `Yesterday at ${formatTime(date)}`;

    return `on ${format(date, 'do MMM, yyyy ')} at ${formatTime(date)}`;
};

const returnformatDateOnly = (date: Date): string => `${format(date, 'do MMM, yyyy ')} at ${formatTime(date)}`;

const convertEpochToDate = (epochTime: number): Date => {
    const date = new Date(0);
    const epochTimeLength = epochTime.toString().length;
    let timeStamp = epochTime;
    if (epochTimeLength > 10) {
        timeStamp /= 1000;
    }
    date.setUTCSeconds(timeStamp);
    return date;
};

const convertEpochToFormatDate = (epochTime: number): string => {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);
    return format(date, 'MM-dd-yyyy');
}

const convertEpochToFormatDateForFiles = (epochTime: number): string => {
    const date = new Date(0);
    date.setUTCSeconds(epochTime);
    return format(date, 'dd/MM/yyyy');
}

export { formatDate, formatTime, convertEpochToDate, formatDateForEvent, returnformatDateOnly, futureFormattedDate, convertEpochToFormatDate, formatDateTime, convertEpochToFormatDateForFiles };
