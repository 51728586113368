/* eslint-disable max-lines-per-function */
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import ReactDatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { useAuthDataContext } from '../../contexts/user-auth-provider';
import { userAutoSearch, createDelegation, updateExistingDelegation } from '../../API/delegation';
import { IOptionValue } from '../../interfaces';
import { IAdminDelegationInfo } from '../../interfaces/delegation';
import { getNextDate, convertToEpoch } from '../../utils';
import STATIC_CONTENT from '../../constants/StaticContent';
import CONSTANTS from '../../constants';
import Toggle from '../toggle/Toggle';

const { DELEGATION_SETTINGS } = STATIC_CONTENT;

interface Props {
    isNewDelegation: boolean;
    updateDelegations: () => void;
    existingDelegation: IAdminDelegationInfo;
    setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const DelegationForm : React.FC<Props> = ({ isNewDelegation, updateDelegations, existingDelegation, setShowEditModal }) => {
    const { user: employee } = useAuthDataContext();
    const today = new Date();
    const tomorrow = getNextDate(today);
    const dateFormat = 'MMM d, yyyy';

    const defaultNewDelegate: IAdminDelegationInfo = {
        rowId: 0,
        delegateFrom: { value: 0, label: '' },
        delegateTo: { value:0, label: ''},
        startDate: today,
        endDate: tomorrow,
        editAccess: false,
        approvalAccess: false
    };
    const [newDelegation, setNewDelegation] = useState(existingDelegation);
    const [editAccess, setEditAccess] = useState(existingDelegation.editAccess);
    const [approvalAccess, setApprovalAccess] = useState(existingDelegation.approvalAccess);
    const [usersLoading, setUsersLoading] = useState(false);
    const [searchText, setSearchText] = useState('');

    const promiseOptions = async (inputValue: string): Promise<IOptionValue[]> => {
        setSearchText(inputValue);
        setUsersLoading(true);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                let users = await userAutoSearch(inputValue, 'ADMIN');
                if (users) {
                    users = users.filter((u) => u.value !== employee.id);
                    resolve(users);
                    setUsersLoading(false);
                }
            } else {
                setUsersLoading(false);
            }
        });
    };

    const handleFromUser = (delegateFrom: IOptionValue) => setNewDelegation({ ...newDelegation, delegateFrom });
    const handleToUser = (delegateTo: IOptionValue) => setNewDelegation({ ...newDelegation, delegateTo });
    const handleFromDate = (date: Date) => {
        if (newDelegation.endDate <= date) {
            setNewDelegation({ ...newDelegation, startDate: date, endDate: getNextDate(date) });
        } else {
            setNewDelegation({ ...newDelegation, startDate: date });
        }
    };
    const handleToDate = (date: Date) => setNewDelegation({ ...newDelegation, endDate: date });

    const handleSaveDelegation = async () => {
        if (newDelegation.startDate > newDelegation.endDate) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.INVALID_DATES);
        } else if (!newDelegation.delegateFrom.value || !newDelegation.delegateTo.value) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.USERS_NOT_CHOSEN);
        } else if (newDelegation.delegateFrom.value === newDelegation.delegateTo.value) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.USERS_SAME);
        } else {
            const formData = {
                delegatorId: newDelegation.delegateFrom.value,
                delegateeId: newDelegation.delegateTo.value,
                startTime: convertToEpoch(newDelegation.startDate),
                endTime: convertToEpoch(newDelegation.endDate),
                editAccess: newDelegation.editAccess,
                approvalAccess: newDelegation.approvalAccess,
                isAdmin: true
            };
            const responseData = await createDelegation(formData);
            if (responseData?.apiStatus === 'SUCCESS') {
                toast.success(DELEGATION_SETTINGS.TOAST.SUCCESS_MSG.DELEGATED);
                updateDelegations();
                setNewDelegation(defaultNewDelegate);
                setEditAccess(false);
                setApprovalAccess(false);
            } else if (responseData?.error.includes('MULTIPLE_DELEGATIONS_FOR_DELEGATOR')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.MULTIPLE_DELEGATIONS_FOR_DELEGATOR);
            } else if (responseData?.error.includes('DELEGATEE_UNAVAILABLE')) {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.DELEGATEE_UNAVAILABLE);
            } else {
                toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
            }
        }
    }

    const handleUpdateDelegation = async () => {
        const formData = {
            id: newDelegation.rowId,
            delegatorId: newDelegation.delegateFrom.value,
            delegateeId: newDelegation.delegateTo.value,
            startTime: convertToEpoch(newDelegation.startDate),
            endTime: convertToEpoch(newDelegation.endDate),
            editAccess: newDelegation.editAccess,
            approvalAccess: newDelegation.approvalAccess,
            isAdmin: true
        };
        const responseData = await updateExistingDelegation(formData);
        if (responseData?.apiStatus === 'SUCCESS') {
            toast.success(DELEGATION_SETTINGS.TOAST.SUCCESS_MSG.UPDATED);
            updateDelegations();
            setShowEditModal(false);
        } else if (responseData?.error.includes('MULTIPLE_DELEGATIONS_FOR_DELEGATOR')) {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.MULTIPLE_DELEGATIONS_FOR_DELEGATOR);
        } else {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.SOMETHING_WENT_WRONG);
        }
    }

    useEffect(() => {
        setNewDelegation({ ...newDelegation, editAccess });
    }, [editAccess]);

    useEffect(() => {
        setNewDelegation({ ...newDelegation, approvalAccess });
    }, [approvalAccess]);

    return (
        <div className="delegation-form">
            <div className="form-row">
                <div className="form-group">
                    <label>Delegate From</label>
                    <AsyncSelect
                        name="delegateFrom"
                        id="delegateFrom"
                        className="react-select-delegate-from"
                        value={newDelegation.delegateFrom}
                        isLoading={usersLoading}
                        hideSelectedOptions
                        loadOptions={promiseOptions}
                        isDisabled={!isNewDelegation}
                        onChange={(option) => {
                            if (option) handleFromUser(option);
                        }}
                        noOptionsMessage={() =>
                            searchText.length >= 3
                                ? 'No results found'
                                : 'Start typing minimum 3 characters'
                        }
                    />
                </div>
                <div className="form-group">
                    <label>Delegate To</label>
                    <AsyncSelect
                        name="delegateTo"
                        id="delegateTo"
                        className="react-select-delegate-to"
                        value={newDelegation.delegateTo}
                        isLoading={usersLoading}
                        hideSelectedOptions
                        loadOptions={promiseOptions}
                        isDisabled={!isNewDelegation}
                        onChange={(option) => {
                            if (option) handleToUser(option);
                        }}
                        noOptionsMessage={() =>
                            searchText.length >= 3
                                ? 'No results found'
                                : 'Start typing minimum 3 characters'
                        }
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <label>From Date</label>
                    <ReactDatePicker
                        className="form-control"
                        selected={newDelegation.startDate}
                        onChange={handleFromDate}
                        dateFormat={dateFormat}
                        selectsStart
                        startDate={newDelegation.startDate}
                        endDate={newDelegation.endDate}
                        minDate={new Date()}
                    />
                </div>
                <div className="form-group">
                    <label>To Date</label>
                    <ReactDatePicker
                        className="form-control"
                        selected={newDelegation.endDate}
                        onChange={handleToDate}
                        dateFormat={dateFormat}
                        selectsEnd
                        startDate={newDelegation.startDate}
                        endDate={newDelegation.endDate}
                        minDate={getNextDate(newDelegation.startDate)}
                    />
                </div>
                <div className="form-group toggle-btn">
                    <label>Edit Access</label>
                    <Toggle
                        isChecked={editAccess}
                        isDisabled={false}
                        onclick={() => setEditAccess(!editAccess)}
                    />   
                </div>
                <div className="form-group toggle-btn">
                    <label>Approval Access</label>
                    <Toggle
                        isChecked={approvalAccess}
                        isDisabled={false}
                        onclick={() => setApprovalAccess(!approvalAccess)}
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group save-btn">
                    {isNewDelegation && (
                        <button type="button" className="primary-btn" onClick={() => handleSaveDelegation()}>
                            Save
                        </button>
                    )}
                    {!isNewDelegation && (
                        <button type="button" className="primary-btn" onClick={() => handleUpdateDelegation()}>
                            Update
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default DelegationForm;