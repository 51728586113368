import React, { useState, Fragment, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';
import 'react-popper-tooltip/dist/styles.css';
import { toast } from 'react-toastify';

import { IDocReferenceInfo, IField, IFieldsData } from '../../interfaces/document';
import { IUiControlTypes, IButtonStatus } from '../../interfaces';
import { useEventsHeight } from '../../contexts/events-height-provider';

import {
    Text,
    SingleSelect,
    TextBox,
    Number,
    DatePicker,
    MultiSelectDropdown,
    TextBoxWithChip,
    DocumentReference,
    Float,
} from '../input-fields';
import ToggleInput from '../input-fields/toggle/ToggleInput';
import './ImportantFields.scss';
import { IDocumentAction } from '../../reducers/document-reducer';
import ButtonSelector from '../input-fields/button-selector/ButtonSelector';
import { InvalidSvg, EditSvg } from '../../assets/images';
import { editToCcFields } from '../../API/document';
import Markup from '../input-fields/markup/Markup';
import { useTaskDetailContext } from '../../contexts/task-details-provider';
import LinkedDocuments from '../input-fields/linked-documents/LinkedDocuments';
import DatePickerDateOnly from '../input-fields/date-picker-date-only/DatePickerDateOnly';
import { useDocumentContext } from '../../contexts/document-provider';

interface IInputComponentProps {
    componentType: IUiControlTypes;
    isDisabled: boolean;
    field: IField;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
    editReference: boolean;
}
// eslint-disable-next-line max-lines-per-function
const InputComponent: React.FC<IInputComponentProps> = ({
    componentType,
    isDisabled,
    field,
    fieldsData,
    documentDispatch,
    errorFields,
    editReference,
}: IInputComponentProps) => {
    const [fieldData] = fieldsData.filter((a) => a.name === field.name);
    switch (componentType) {
        case 'Text':
            return (
                <Text
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Text Box':
            return (
                <TextBox
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    error={errorFields.includes(field.name)}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                />
            );
        case 'Single Select Dropdown':
            return (
                <SingleSelect
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Multi Select Dropdown':
            return (
                <MultiSelectDropdown
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Number':
            return (
                <Number
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as number) : 0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Float':
            return (
                <Float

                    documentDispatch={documentDispatch || null}
                    field={field}
                    step={field.step}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as number) : 0.0}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'DatePicker':
            return (
                <DatePicker
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? new Date(fieldData.value as Date) : new Date()}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'OnlyDate':
            return (
                <DatePickerDateOnly
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? fieldData.value : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Text Box With Chip':
            return (
                <TextBoxWithChip
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Toggle':
            return (
                <ToggleInput
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as boolean) : false}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Button Select':
            return (
                <ButtonSelector
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string) : ''}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Table':
            return (
                <DocumentReference
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as IDocReferenceInfo[]) : []}
                    error={errorFields.includes(field.name)}
                />
            );
        case 'Reference':
            return (
                <LinkedDocuments
                    documentDispatch={documentDispatch || null}
                    field={field}
                    isDisabled={isDisabled}
                    value={fieldData?.value ? (fieldData.value as string[]) : []}
                    error={errorFields.includes(field.name)}
                    isEditable={editReference}
                    isChild={false}
                />
            );
        case 'Markup':
            return <Markup field={field} />;
        default:
            return <div>something is wrong</div>;
    }
};
interface FieldsToolTipProps {
    isDisabled: boolean;
    field: IField;
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    errorFields: string[];
}
// eslint-disable-next-line max-lines-per-function
const FieldsToolTip = ({
    isDisabled,
    field,
    fieldsData,
    documentDispatch,
    errorFields,
}: FieldsToolTipProps) => {
    const halfUiControl: IUiControlTypes[] = [
        'DatePicker',
        'Number',
        'Single Select Dropdown',
        'Toggle',
    ];
    const { isTaskActive, taskDocumentDispatch, updateFields } = useTaskDetailContext();
    const { updateHeight } = useEventsHeight();
    useEffect(() => {
        updateHeight();
    });
    const [buttonStatus, setButtonStatus] = useState<IButtonStatus>('DEFAULT');
    const [editReference, setEditReference] = useState(false);
    const [editToCc, setEditToCc] = useState(false);
    const handleClick = async () => {
        setButtonStatus('LOADING');
        await updateFields();
        setButtonStatus('DEFAULT');
        setEditReference(false);
        setEditToCc(false);
    };
    const { dateErrorInfo } = useDocumentContext();
    let displayField = true;
    if (fieldsData.length !== 0 && field.name === 'businessPurposeDescription') {
        const [toggleField] = fieldsData.filter((a) => a.name === 'specificBusinessPurpose');
        displayField = toggleField.value;
    }
    return (
        displayField ?
            <>
                <div
                    key={field.name}
                    className={`${halfUiControl.includes(field.uiControl.label) ? 'half' : 'full'}`}
                >
                    {field.markupText === '' && (
                        <label
                            htmlFor={field.name}
                            className={field.mandatory ? 'mandatory-field' : ''}
                        >
                            {field.displayName}
                        </label>
                    )}
                    <div data-for="input-component" data-tip={!isDisabled ? field.helperText : null}>
                        <>
                            {field.name === 'LinkedDocuments' && isTaskActive && !editReference ? (
                                <EditSvg
                                    style={{
                                        cursor: 'pointer',
                                        float: 'right',
                                        position: 'relative',
                                    }}
                                    className="edit-icon"
                                    onClick={() => setEditReference(true)}
                                />
                            ) : (
                                (field.name === 'to' || field.name === 'cc' || field.name === 'cC') &&
                                isTaskActive &&
                                !editToCc && (
                                    <EditSvg
                                        style={{
                                            cursor: 'pointer',
                                            float: 'right',
                                            position: 'relative',
                                        }}
                                        className="edit-icon"
                                        onClick={() => setEditToCc(true)}
                                    />
                                )
                            )}
                            <InputComponent
                                componentType={field.uiControl.label}
                                isDisabled={
                                    (field.name === 'LinkedDocuments' &&
                                        isTaskActive &&
                                        editReference) ||
                                        ((field.name === 'to' ||
                                            field.name === 'cc' ||
                                            field.name === 'cC') &&
                                            isTaskActive &&
                                            editToCc)
                                        ? false
                                        : isDisabled
                                }
                                field={field}
                                documentDispatch={documentDispatch}
                                fieldsData={fieldsData}
                                errorFields={errorFields || []}
                                editReference={editReference}
                            />
                            {errorFields.includes(field.name) && (
                                <span className="error-message">
                                    <InvalidSvg /> This field is mandatory
                                </span>
                            )}
                            {dateErrorInfo.errorFields.includes(field.name) && (
                                <span className="error-message">
                                    <InvalidSvg /> Date is Invalid
                                </span>
                            )}
                            {((field.name === 'LinkedDocuments' && editReference) ||
                                ((field.name === 'to' || field.name === 'cc' || field.name === 'cC') &&
                                    editToCc)) && (
                                    <button
                                        className="update-button"
                                        type="button"
                                        disabled={buttonStatus === 'LOADING'}
                                        onClick={() => handleClick()}
                                    >
                                        Update
                                    </button>
                                )}
                        </>
                        <ReactTooltip id="input-component" place="left" effect="solid" type="dark" />
                    </div>
                </div>
            </> : null
    );
};
interface Props {
    isDisabled: boolean;
    fields: IField[];
    fieldsData: IFieldsData[];
    documentDispatch: React.Dispatch<IDocumentAction> | null;
    // eslint-disable-next-line react/require-default-props
    errorFields?: string[];
}
const SkeletonImportantFields = (): JSX.Element => (
    <div className="full">
        <label htmlFor="">
            <Skeleton />
        </label>
        <div>
            <Skeleton width="80%" />
        </div>
    </div>
);
// eslint-disable-next-line max-lines-per-function
const ImportantFieldsForTask: React.FC<Props> = ({
    isDisabled,
    fields,
    fieldsData,
    documentDispatch,
    errorFields,
}: Props) => (
    <div className="important-fields">
        {fields.length > 0 ? (
            fields.map((field) => (
                <>
                    <FieldsToolTip
                        key={field.id}
                        isDisabled={isDisabled}
                        field={field}
                        documentDispatch={documentDispatch}
                        fieldsData={fieldsData}
                        errorFields={errorFields || []}
                    />
                </>
            ))
        ) : (
            <>
                <SkeletonImportantFields />
                <SkeletonImportantFields />
                <SkeletonImportantFields />
            </>
        )}
    </div>
);
export default ImportantFieldsForTask;
