/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import Modal from 'react-modal';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { IUserOptionValue } from '../../interfaces';
import './userSearchModal.scss';
import { userSearch } from '../../API/users';
import { PlusIconSvg } from '../../assets/images';
import CONSTANTS from '../../constants';
import STATIC_CONTENT from '../../constants/StaticContent';
import { useDocumentContext } from '../../contexts/document-provider';

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, -50%)',
    },
};

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    userHandler: (selectedUser: IUserOptionValue[], type: 'EDIT' | 'NEW') => void;
    type: 'EDIT' | 'NEW';
    hideUsers: number[];
    // eslint-disable-next-line react/require-default-props
    roleId?: number[];
    // eslint-disable-next-line react/require-default-props
    groupId?: number;
    // eslint-disable-next-line react/require-default-props
    setEditedApprovers?: React.Dispatch<React.SetStateAction<boolean>>;
}
Modal.setAppElement('#root');
const UserSearchModal: React.FC<Props> = ({
    isModalOpen,
    onModalClose,
    userHandler,
    roleId = [],
    groupId,
    hideUsers,
    type,
    setEditedApprovers
}: Props) => {
    const [isLoading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const { isLeadershipForm } = useDocumentContext();
    const [selectedOptions, setSelectedOptions] = useState<IUserOptionValue[]>([]);

    const promiseOptions = async (inputValue: string): Promise<IUserOptionValue[]> => {
        setLoading(true);
        setSearchText(inputValue);
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve) => {
            if (inputValue.length >= CONSTANTS.USER_SEARCH_LENGTH) {
                const users = await userSearch(inputValue, roleId, undefined, undefined, groupId);
                if (users?.apiStatus === 'SUCCESS') {
                    let userList;
                    if (isLeadershipForm) {
                        userList = users.users.filter((u) => u.value as number);
                    } else {
                        userList = users.users.filter(
                            (u) => !hideUsers.includes(u.value as number),
                        );
                    }
                    if ((userList.length > 0) && (inputValue.indexOf('<') > -1) || (inputValue.indexOf(',') > -1)) {
                        userHandler(userList, type);
                    }
                    resolve(userList);
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        });
    };


    return (
        <div className="user-search-modal">
            <Modal
                isOpen={isModalOpen}
                style={customStyles}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="user-search__modal"
            >
                <PlusIconSvg className="close-btn" onClick={() => {
                    if(setEditedApprovers)
                    {
                        setEditedApprovers(false)
                    }
                    onModalClose(false)
                }} />
                <label htmlFor="user-search">{STATIC_CONTENT.USER_SEARCH.LABEL.SELECT_USERS}</label>
                <span className="note">Length should be greater than 3 characters</span>
                <AsyncSelect
                    name="user-search"
                    id="user-search"
                    components={animatedComponents}
                    isLoading={isLoading}
                    isMulti
                    placeholder="Search user..."
                    className="react-select user-search"
                    classNamePrefix="select"
                    loadOptions={promiseOptions}
                    getOptionLabel={(e) => `${e.label} | ${e.jobTitle}`}
                    noOptionsMessage={() => searchText.length < 3 ? 'Enter minimum 3 characters' : 'No results found'}
                    onChange={(options) => {
                        if (!options)
                            return;
                        setSelectedOptions(options as IUserOptionValue[]);
                        setSearchText('');
                    }}
                />
                <div className="button-grp">
                    <button type="submit" className="primary-btn" onClick={() => {
                        userHandler([...selectedOptions], type);
                        onModalClose(false);
                    }}>
                        SAVE
                    </button>
                    <button type="submit" onClick={() => {
                    if(setEditedApprovers)
                    {
                        setEditedApprovers(false)
                    }
                    onModalClose(false)
                }}>
                        CANCEL
                    </button>
                </div>
            </Modal>
        </div >
    );
};

export default UserSearchModal;
