/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import makeAnimated from 'react-select/animated';
import { PlusIconSvg } from '../../assets/images';
import STATIC_CONTENT from '../../constants/StaticContent';
import { IExternalApprover, IModalData } from '../../interfaces';
import './externalApproverModal.scss'

const animatedComponents = makeAnimated();

const customStyles = {
    content: {
        // backgroundColor: '#000',
        bottom: 'auto',
        left: '50%',
        marginRight: '-50%',
        right: 'auto',
        top: '20%',
        transform: 'translate(-50%, -50%)',
    },
};

interface Props {
    isModalOpen: boolean;
    onModalClose: React.Dispatch<React.SetStateAction<boolean>>;
    submitHandler: (selectedUser: IExternalApprover, type: 'EDIT' | 'NEW') => void;
    modalData: IModalData;
    // eslint-disable-next-line react/require-default-props
    setUpdateExternalApprover?: React.Dispatch<React.SetStateAction<boolean>>;
}
Modal.setAppElement('#root');
const UserSearchModal: React.FC<Props> = ({ isModalOpen, onModalClose, submitHandler,modalData,setUpdateExternalApprover }: Props) => {
    const [email, setEmail] = useState(modalData.email);
    const [name, setName] = useState(modalData.name);

    useEffect(()=>{
        setEmail(modalData.email);
        setName(modalData.name);
    },[modalData])

    return (
        <div className="user-search-modal">
            <Modal
                isOpen={isModalOpen}
                style={customStyles}
                onRequestClose={() => onModalClose(false)}
                contentLabel="Example Modal"
                className="external-approver_modal"
            >
                <PlusIconSvg
                    className="close-btn"
                    onClick={() => {
                        if (setUpdateExternalApprover) {
                            setUpdateExternalApprover(false);
                        }
                        onModalClose(false);
                    }}
                />
                <label htmlFor="user-search" className="external-approver_modal_header">
                    {STATIC_CONTENT.USER_SEARCH.LABEL.ADD_EXTERNAL_APPROVERS}
                </label>
                <form
                    className="external-approver-form"
                    onSubmit={(event) => {
                        event.preventDefault();
                        submitHandler(
                            {
                                email,
                                name,
                                state: 'NEW',
                                updatedAt: new Date().getTime(),
                            },
                            modalData.approverIndex === -1 ? 'NEW' : 'EDIT',
                        );
                    }}
                >
                    <div className="external-approver-form-data">
                        <div className="external-modal-formGroup">
                            <label className="label-modal">Email:</label>
                            <input
                                type="email"
                                className="label-input"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                        <div className="external-modal-formGroup">
                            <label className="label-modal">Name:</label>
                            <input
                                type="text"
                                className="label-input"
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </div>
                    </div>
                    <button type="submit">Submit</button>
                </form>
            </Modal>
        </div>
    );
};

export default UserSearchModal;
