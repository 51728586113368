/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import { getRequest } from '../../utils/api-request';
import CONSTANTS from '../../constants';
import { SkeletonText } from '../Skeleton';

// eslint-disable-next-line max-lines-per-function
const ContractsStatusCount: React.FC = () => {
    const stateCount: { [key: string]: string | number } = {
        DRAFT: CONSTANTS.LOADING.TEXT,
        UNDER_REVIEW: CONSTANTS.LOADING.TEXT,
        REJECTED: CONSTANTS.LOADING.TEXT,
        PUBLISHED: CONSTANTS.LOADING.TEXT,
    };
    const [data, setData] = useState(stateCount);
    const [count, setCount] = useState(CONSTANTS.LOADING.NUMBER);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getRequest(`document-types/contract-count`);
                if (res && res?.apiStatus === 'SUCCESS') {
                    res.response.contractsCount.forEach((item: { state: string; contract_count: number }) => {
                        if (item.state in stateCount) {
                            stateCount[item.state] = item.contract_count || '0';
                        }
                    });
                    Object.entries(stateCount).forEach(([key,value]) => {
                        if (value === CONSTANTS.LOADING.TEXT) {
                            stateCount[key] = '0';
                        }
                    });
                    setData(stateCount);
                    setCount(res.response.totalCount);
                } else console.error('Fetching data failed with status', res?.apiStatus);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            <div className="statusEach">
                <h3>TOTAL COUNT:</h3>
                <p>
                    <SkeletonText text={count} width={75} />
                </p>
            </div>
            {Object.entries(data).map(([key, value]) => (
                <div className="statusEach" key={key}>
                    <h3>{`${key} CONTRACTS:`}</h3>
                    <p>
                        <SkeletonText text={value} width={75} />
                    </p>
                </div>
            ))}
        </>
    );
};
export default ContractsStatusCount;
