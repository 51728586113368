/* eslint-disable max-lines-per-function */
import { IOptionValue } from '../interfaces';
import { IAttachmentMeta, IFileTypeDoc } from '../interfaces/attachment';
import { IDocReferenceInfo, IFieldsData } from '../interfaces/document';

export type IDocumentAction =
    | { type: 'push'; value: IFieldsData[] }
    | { type: 'text'; inputName: string; value: string }
    | { type: 'text-box'; inputName: string; value: string }
    | { type: 'single-select-dropdown'; inputName: string; value: IOptionValue[] }
    | { type: 'number'; inputName: string; value: number }
    | { type: 'float'; inputName: string; value:  number }
    | { type: 'date-picker'; inputName: string; value: Date }
    | { type: 'date-picker-date-only'; inputName: string; value: string }
    | { type: 'multi-select-dropdown'; inputName: string; value: IOptionValue[] }
    | { type: 'text-box-with-chip'; inputName: string; value: string[] }
    | { type: 'toggle'; inputName: string; value: boolean }
    | { type: 'button-select'; inputName: string; value: string }
    | { type: 'document-reference'; inputName: string; value: IDocReferenceInfo[] }
    | { type: 'reference'; inputName: string; value: (string | number)[] }
    | { type: 'file-list'; inputName: string; value: IFileTypeDoc[] }
    | { type: 'file-list-update'; inputName: string; value: IFileTypeDoc[] }
    | { type: 'file-list-update-primary'; inputName: string; fileNumber: string }
    | { type: 'remove-file'; fIdx: number };

export const documentReducer = (state: IFieldsData[], action: IDocumentAction): IFieldsData[] => {
    switch (action.type) {
        case 'push':
            return action.value;
        case 'text':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'text-box':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'single-select-dropdown':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'multi-select-dropdown':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'number':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
            case 'float':
                return [
                    ...state.slice(
                        0,
                        state.findIndex((a) => a.name === action.inputName),
                    ),
                    {
                        ...state[state.findIndex((a) => a.name === action.inputName)],
                        value: action.value,
                    },
                    ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
                ];
        case 'toggle':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'date-picker':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'date-picker-date-only':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'text-box-with-chip':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'button-select':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'document-reference':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'reference':
            return [
                ...state.slice(
                    0,
                    state.findIndex((a) => a.name === action.inputName),
                ),
                {
                    ...state[state.findIndex((a) => a.name === action.inputName)],
                    value: action.value,
                },
                ...state.slice(state.findIndex((a) => a.name === action.inputName) + 1),
            ];
        case 'file-list': {
            // check if state[i].name exists
            const matchingIdx = state.findIndex((a) => a.name === action.inputName);
            if(matchingIdx === -1) {
                return [
                    ...state,
                    { name: 'file', value: action.value}
                ]
            } 
            return [
                ...state.slice(
                    0,
                    matchingIdx,
                ),
                {
                    ...state[matchingIdx],
                    value: [...state[matchingIdx].value, ...action.value],
                },
                ...state.slice(matchingIdx + 1),
            ];
        }
        case 'file-list-update': {
            const matchingIdx = state.findIndex((a) => a.name === action.inputName);
            const fileList: IFileTypeDoc[] = state[matchingIdx].value;
            const [updatedFile] = action.value;
            let fIdx = -1;
            /* eslint-disable no-plusplus */
            for(let i=0; i<fileList.length; i++){
                const currFile = fileList[i];
                if(currFile.id === updatedFile.id) {
                    fIdx = i;
                    break;
                }
            }
            const newFileList = [
                ...fileList.slice(0, fIdx),
                updatedFile,
                ...fileList.slice(fIdx+1)
            ]
            return [
                ...state.slice(
                    0,
                    matchingIdx,
                ),
                {
                    ...state[matchingIdx],
                    value: newFileList,
                },
                ...state.slice(matchingIdx + 1),
            ]
        }
        case 'file-list-update-primary': {
            const matchingIdx = state.findIndex((a) => a.name === action.inputName);
            const fileList: IFileTypeDoc[] = state[matchingIdx].value;
            const { fileNumber } = action;
            const newFileList: IFileTypeDoc[] = [];
            /* eslint-disable no-plusplus */
            for(let i=0; i<fileList.length; i++){
                const currFile = fileList[i];
                const newFile = {...currFile}
                if(newFile.fileNumber === fileNumber) {
                    // update isPrimary
                    newFile.isPrimary = true;
                } else {
                    newFile.isPrimary = false;
                }
                newFileList.push(newFile);
            }
            return [
                ...state.slice(
                    0,
                    matchingIdx,
                ),
                {
                    ...state[matchingIdx],
                    value: newFileList,
                },
                ...state.slice(matchingIdx + 1),
            ]
        }
        case 'remove-file': {
            const matchingIdx = state.findIndex((a) => a.name === 'file');
            if(matchingIdx !== -1) {
                const fileArr = state[matchingIdx].value;
                const newFileArr = [
                    ...fileArr.slice(0, action.fIdx),
                    ...fileArr.slice(action.fIdx+1)
                ]
                return [
                    ...state.slice(
                        0,
                        matchingIdx,
                    ),
                    {
                        ...state[matchingIdx],
                        value: newFileArr,
                    },
                    ...state.slice(matchingIdx + 1),
                ]
            }
            return state;
        }
        default:
            return state;
    }
};
