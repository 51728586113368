/* eslint-disable max-lines-per-function */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Column, usePagination, useTable, useSortBy } from 'react-table';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import qs from 'qs';
import { SortIcon, TickIcon, CrossIcon, EditSvg, DeleteSvg, PlusIconSvg } from '../../assets/images';
import { IPaginationData } from '../../interfaces';
import { IAdminDelegations, IAdminDelegationInfo } from '../../interfaces/delegation';
import { getAllDelegations, removeDelegation } from '../../API/delegation';
import DelegationForm from './DelegationForm';
import { DateColumn } from '../General';
import Pagination from '../pagination/Pagination';
import { getNextDate } from '../../utils';
import STATIC_CONTENT from '../../constants/StaticContent';
import CONSTANTS from '../../constants';
import { SkeletonText } from '../Skeleton';
import './Delegations.scss';

const { DELEGATION_SETTINGS } = STATIC_CONTENT;

type AccessValue = true | false | undefined;
const DelegationAccessIcon = ({ value } : { value : AccessValue }) => (
    <div>
        {value ? (
            <TickIcon width="34px" height="34px" />
        ) : (
            <CrossIcon width="34px" height="34px" />
        )}
    </div>
);

const COLUMNS : Column<IAdminDelegations>[] = [
    {
        Header: 'Delegated From',
        accessor: 'delegatedFrom',
        minWidth: 200,
        Cell: ({ value }) => <SkeletonText text={value} width={200} />,
    },
    {
        Header: 'Delegated To',
        accessor: 'delegatedTo',
        minWidth: 200,
        Cell: ({ value }) => <SkeletonText text={value} width={200} />,
    },
    {
        Header: 'From',
        accessor: 'startDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: 100

    },
    {
        Header: 'To',
        accessor: 'endDate',
        Cell: ({ value }) => <DateColumn value={value} />,
        minWidth: 100
    },
    {
        Header: 'Edit Access',
        accessor: 'editAccess',
        Cell: ({ value }) => <DelegationAccessIcon value={value} />,
        disableSortBy: true
    },
    {
        Header: 'Approval Access',
        accessor: 'approvalAccess',
        Cell: ({ value }) => <DelegationAccessIcon value={value} />,
        disableSortBy: true
    },
    {
        Header: 'Edit',
        disableSortBy: true
    },
    {
        Header: 'Delete',
        accessor: 'id',
        disableSortBy: true
    }
];

const Delegations : React.FC = () => {
    const today = new Date();
    const tomorrow = getNextDate(today);
    const DEFAULT_PAGINATION_DATA: IPaginationData = {
        pageIndex: 0,
        pageSize: CONSTANTS.NO_OF_TABLE_ROWS,
        totalCount: 0,
    };
    const defaultNewDelegate: IAdminDelegationInfo = {
        rowId: 0,
        delegateFrom: { value: 0, label: '' },
        delegateTo: { value:0, label: ''},
        startDate: today,
        endDate: tomorrow,
        editAccess: false,
        approvalAccess: false
    };
    const defaultDelegations: IAdminDelegations = {
        id: CONSTANTS.LOADING.NUMBER,
        delegatedFrom: CONSTANTS.LOADING.TEXT,
        delegatedTo: CONSTANTS.LOADING.TEXT,
        delegatorId: CONSTANTS.LOADING.NUMBER,
        delegateeId: CONSTANTS.LOADING.NUMBER,
        startDate: CONSTANTS.LOADING.DATE,
        endDate: CONSTANTS.LOADING.DATE,
        approvalAccess: false,
        editAccess: false
    }

    const [delegations, setDelegations] = useState<IAdminDelegations[]>([defaultDelegations]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [existingDelegation, setExistingDelegation] = useState(defaultNewDelegate);
    const [pagination, setPagination] = useState(DEFAULT_PAGINATION_DATA);

    const columns = useMemo(() => COLUMNS, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        previousPage,
        nextPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: delegations || [],
            manualPagination: true,
            autoResetPage: false,
            pageCount: Math.ceil(pagination.totalCount / pagination.pageSize)
        },
        useSortBy,
        usePagination
    );

    const updateDelegations = async () => {
        const queryParams = {
            limit: pageSize.toString(),
            offset: (pageIndex * pageSize).toString()
        };
        const allDelegations = await getAllDelegations(qs.stringify(queryParams));
        if (allDelegations?.apiStatus === 'SUCCESS') {
            setDelegations(allDelegations?.delegations);
            setPagination({
                pageSize,
                pageIndex,
                totalCount: allDelegations.totalDelegations,
            });
        }
    };

    const editDelegation = async (cell: any) => {
        const { id, delegatedFrom, delegatedTo, delegatorId, delegateeId, startDate, endDate, editAccess, approvalAccess } = cell?.row?.original;
        setExistingDelegation({
            rowId: id,
            delegateFrom: { value: delegatorId, label: delegatedFrom },
            delegateTo: { value: delegateeId, label: delegatedTo },
            startDate: new Date(parseInt(startDate, 10)),
            endDate: new Date(parseInt(endDate, 10)),
            editAccess,
            approvalAccess
        });
        setShowEditModal(true);
    };

    const deleteDelegation = async (rowId: any) => {
        const response = await removeDelegation(rowId);
        if (response?.apiStatus === 'SUCCESS') {
            updateDelegations();
        } else {
            toast.error(DELEGATION_SETTINGS.TOAST.ERROR_MSG.UNABLE_TO_DELETE);
        }
    };

    useEffect(() => {
        updateDelegations();
    }, [pageIndex, pageSize]);

    return (
        <div className="all-delegations">
            <DelegationForm
                isNewDelegation
                updateDelegations={updateDelegations}
                existingDelegation={existingDelegation}
                setShowEditModal={setShowEditModal}
            />
            <div className="table-section">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => (
                                    <th 
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        {...column.getHeaderProps({style: {textAlign: 'left'}})}
                                    >
                                        {column.render('Header')}
                                        {column.isSorted && (
                                            <SortIcon
                                                className={`sort ${
                                                    column.isSortedDesc ? 'desc' : 'asc'
                                                } `}
                                            />
                                        )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        if (cell.column.Header==='Delete') {
                                            return (
                                                <td>
                                                    <DeleteSvg style={{cursor: 'pointer'}} onClick={()=>deleteDelegation(cell.value)}/>
                                                </td>
                                            )
                                        }
                                        if (cell.column.Header==='Edit') {
                                            return (
                                                <td>
                                                    <EditSvg style={{cursor: 'pointer'}} onClick={()=>editDelegation(cell)}/>
                                                </td>
                                            )
                                        }
                                        return (
                                            <td
                                                className={cell.column.id}
                                                {...cell.getCellProps({
                                                    style: {
                                                        minWidth: cell.column.minWidth
                                                        ? cell.column.minWidth
                                                        : 'auto'
                                                    },
                                                })}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <Pagination
                    nextPage={nextPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                />
            </div>

            <Modal
                isOpen={showEditModal}
                onRequestClose={() => setShowEditModal(false)}
                contentLabel="Edit Delegation Modal"
                className="edit-delegation-modal"
                shouldCloseOnOverlayClick={false}
            >
                <PlusIconSvg className="close-btn" onClick={() => setShowEditModal(false)} />
                <div className="modal-body">
                    <h3>Edit Delegation</h3>
                    <DelegationForm
                        isNewDelegation={false}
                        updateDelegations={updateDelegations}
                        existingDelegation={existingDelegation}
                        setShowEditModal={setShowEditModal}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default Delegations;